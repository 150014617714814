import React from "react"

import { Controller } from "react-hook-form";
import { List } from "src/mtska-frontend-modules/modules/Car/views";
// import { List  } from '../../';

const InputList = ({ control, editing, name, label, readonly, className = "", dataProviderName, defaultSelectedValues, enableSelection, disableMultipleRowSelection, filters }) => {
    if (!editing) readonly = true;

    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, value, name },
                fieldState: { error, invalid },
                formState,
            }) => {

                const rowSelectionExternalListener = (selectionModel) => {
                    onChange(selectionModel);
                }
                return (
                    <>
                        Deprecated, do not use
                        {/*
                        <List
                            leftButtons={[]}
                            rightButtons={[]}
                            dataProviderName={dataProviderName}
                            title={label}
                            rowSelectionExternalListener={onChange}

                            defaultSelectedValues={defaultSelectedValues}
                            enableSelection={enableSelection}
                            disableMultipleRowSelection={disableMultipleRowSelection}

                            className={className}
                            isEmbedded={true}
                            filters={filters}
                        /> */}
                    </>
                )
            }}
        />
    );
};


export default InputList;
