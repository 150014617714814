import React from 'react';
import Detail from './Detail';
import { InfoHeader } from '../../../../mtska-frontend-app-component';
import { useTranslation } from 'react-i18next';

const Create = (params) => {
    const { t } = useTranslation();

    return <Detail 
        title={t('New Setup')}
        header={
            <InfoHeader
                view="wizard"
                title={t('Create new Setup')}
                subtitle={t('Fill the information to create a new Setup that you will be able to edit via the standard interface')}
            />
        }
        {...params}
    />
}

export default Create;


