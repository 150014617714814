/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// import { actions as authActions } from '../../../../_model/authentication/actions';
// import validate from 'validate.js';
import { Box } from '@mui/joy';
import { Auth0LoginButton } from '../Auth0';
import { Text } from '../../../../../mtska-frontend-app-component';

const schema = {
    email: {
        presence: { allowEmpty: false, message: 'is required' },
        email: true,
        length: {
            maximum: 64
        }
    },
    password: {
        presence: { allowEmpty: false, message: 'is required' },
        length: {
            maximum: 128
        }
    }
};



const SignIn = props => {

    const navigate = useNavigate()



    
    // const auth = useSelector();
    const loggedIn = false //useSelector(state => state.authentication.loggedIn);

    useEffect(() => {
        if (loggedIn) {
            navigate('/');

        }
    }, [loggedIn]);

    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {}
    });

    const dispatch = useDispatch();


    useEffect(() => {
        // const errors = validate(formState.values, schema);
        const errors = {};

        setFormState(formState => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {}
        }));
    }, [formState.values]);


    const handleChange = event => {

        event.persist();

        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]:
                    event.target.type === 'checkbox'
                        ? event.target.checked
                        : event.target.value
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true
            }
        }));
    };

    const handleSignIn = event => {
        event.preventDefault();

        const { email, password } = formState.values;
        if (email && password) {
            // dispatch(authActions.login(email, password));
        }

    };

    const hasError = field => formState.touched[field] && formState.errors[field] ? true : false;

    return (
        <Box className="app-login-panel">
            <img alt="Consecution NextFleet" src="https://www.consecutiongroup.com/wp-content/uploads/2021/11/logo-consecution-group_350.jpg" width="162" align="center" ></img>
            
            <Text level="h4">Consecution Ecosistema</Text>
            <Text level="h1">{process.env.REACT_APP_FULL_NAME}</Text>
            <Text level="body-md">{process.env.REACT_APP_LOGIN_PROMPT}</Text>
            <Auth0LoginButton className="input-button big"/>
        </Box>
    );
};

SignIn.propTypes = {

};

// export default withRouter(SignIn);
export default SignIn;

