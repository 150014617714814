import React from 'react';
import { ListItem, ListItemContent, Box } from "@mui/joy";
import { Button } from '../Button';

import { useTranslation } from 'react-i18next'
import PersonAvatar from '../Snippet/PersonAvatar';
import { Icon } from '../../assets';
import { useDrawer } from '../Drawer'


const RelationshipEmployment = ({ k, v, editing, handleItemDetail, handleItemRemove, filters, relation, callback }) => {
    const { t, i18n } = useTranslation();

    const { activate } = useDrawer();

    return (

        <ListItem k={k} variant="outlined" className={"relationship-element"}>
            <ListItemContent className={"relationship-element-content"}>
                <PersonAvatar firstname={v?.firstname || v?.name} lastname={v?.lastname} url={v?.picture?.url} />
                <Box className="name">
                    <Button
                        size="medium"
                        onClick={() => activate(relation.drawerRouteDetail, {
                            filters: filters
                        }, callback)}
                        // icon={openButtonIcon} 
                        variant={'small'}
                        className="relationship-activator" >
                        <span className="name">{(v?.person?.firstname || v?.person?.name) + " " + (v?.person?.lastname ?? '')}</span>
                    </Button>
                </Box>

                <Box className="coupled">
                    <Box className="label">{t('Role')}</Box>
                    <Box className="value">
                        <span>{v?.role}</span>
                    </Box>
                </Box>

                <Box className="coupled">
                    <Box className="label">{t('LegalEntity')}</Box>
                    <Box className="value">
                        <span>{v?.legalEntity?.name}</span>
                    </Box>
                </Box>
            </ListItemContent>

            {editing && (<Button onClick={() => handleItemDetail(v)} icon="faPen" variant="plain" />)}
            {(editing && handleItemRemove) && (<Button onClick={() => handleItemRemove(v)} icon="faClose" variant="plain" />)}
        </ListItem>
    )
}

export default RelationshipEmployment



