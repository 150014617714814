import React from "react"

import { ServicesOfEmployment, EmploymentsOfPerson } from "../../../mtska-frontend-modules";

const WidgetServicesOfDriver = ({ editing, item, readonly }) => {
    if (!editing) readonly = true;

    return (
        <>
            {
                Array.isArray(item.employments) && item.employments.map((employment, k) => (
                    // se non ha un employment
                    <ServicesOfEmployment
                        filters={employment}
                        key={k}
                    />
                ))
            }

            {

                (item.id && (!Array.isArray(item.employments) || item.employments.length == 0)) && (
                    // se non ha un employment
                    <EmploymentsOfPerson
                        filters={item}
                    />

                )
            }
        </>
    );


};


export default WidgetServicesOfDriver;
