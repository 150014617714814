import React from "react";
import { useContext } from "react";
import { ActiveModuleName } from '../../../mtska-frontend-app-component';
import { AppContext } from '../../contexts';

const Header = () => {

    const { currentModuleName, currentModuleScope, currentModuleNavigationData } = useContext(AppContext);

    return (
        <header>
            <ActiveModuleName currentModuleName={currentModuleName} currentModuleScope={currentModuleScope} />
            <div className="filler"></div>
        </header>
    );
}

export default Header;
