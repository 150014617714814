import React, { useEffect, useState } from "react";
import { useDataProvider } from "src/mtska-frontend-data-provider";
import { Box, Table } from '@mui/joy';
import { useTranslation } from 'react-i18next';

import { WidgetCostElementTimeFrame, WidgetCostElementRows, WidgetCostElementTotal } from '.';

const WidgetCostSupplier = ({ editing, item, readonly }) => {
    const { t } = useTranslation();
    const { item: costbreakdown, loadOne } = useDataProvider('costbreakdown/supplier')

    const [timeFrame, setTimeFrame] = useState('0');
    const [isDrawable, setIsDrawable] = useState(false);

    useEffect(() => {
        if (typeof item.id == 'undefined') return;
        loadOne(item.id + '/' + timeFrame);
    }, [item, timeFrame]);

    useEffect(() => {
        setIsDrawable(true)
    }, [costbreakdown]);

    return (
        isDrawable && <Box className="form-element form-element-cost-widget col-12">
            <WidgetCostElementTimeFrame onChange={setTimeFrame} timeFrame={timeFrame}/>
            <Table className="form-element-cost-table">
                <thead>
                    <tr className="sourceHead">
                        <th>{t('costType')}</th>
                        <th>{t('work_cost')}</th>
                        <th>{t('personal_cost')}</th>
                    </tr>
                </thead>
                <tbody>
                    <WidgetCostElementRows costbreakdown={costbreakdown} />
                </tbody>
                <tfoot>
                    <WidgetCostElementTotal costbreakdown={costbreakdown} />
                </tfoot>
            </Table>
        </Box>
    );
}

export default WidgetCostSupplier;