import React from 'react';
import { Text } from '../Typography';
import { Box } from "@mui/joy"

const DetailTitle = ({title, subtitle, icon}) => {
    return (
        <Box className={'drawer-section-title'}>
        <Text level="title-md" icon className="title">{title}</Text>
        { subtitle && (
            <Text level="body-md" className="detail">{subtitle}</Text>
        )}
        </Box>
    )
}

export default DetailTitle;