export const useDetailConfigOverrider = () => {

    const overrideDetailFields = function (originalData, corrections) {

        corrections.tabs.forEach((correctionTab, tabIndex) => {
            correctionTab.fieldsets.forEach((correctionFieldset, fieldsetIndex) => {
                Object.keys(correctionFieldset.fields).forEach((fieldKey) => {
                    const fieldValue = correctionFieldset.fields[fieldKey];

                    if (originalData.tabs[tabIndex].fieldsets[fieldsetIndex].fields[fieldKey]) {

                        if (fieldValue === false) {
                            delete originalData.tabs[tabIndex].fieldsets[fieldsetIndex].fields[fieldKey];
                        } else {
                            originalData.tabs[tabIndex].fieldsets[fieldsetIndex].fields[fieldKey] = fieldValue;
                        }

                    } else {
                        originalData.tabs[tabIndex].fieldsets[fieldsetIndex].fields[fieldKey] = fieldValue;
                    }
                });
            });
        });

        return originalData;
    }


    return {
        overrideDetailFields
    }
};
