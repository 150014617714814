import React from 'react';
import { Box } from '@mui/joy';
import { KPI } from '../../../../mtska-frontend-app-component';
import List from './List';

const Default = () => {

    return <>
        <Box className="mtksa-chart-wrapper">
            <KPI
                title="Vehicles"
                icon="faContract"
                score={26373}>
            </KPI>
            <KPI
                title="Drivers"
                icon="faSteeringWheel"
                score={8894}>
            </KPI>
        </Box>
        <List />
    </>

}

export default Default;


