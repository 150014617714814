import React from "react";
import { Select, Option } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Icon } from '../../assets';

const WidgetCostElementTimeFrame = ({ onChange, timeFrame }) => {
    const { t } = useTranslation();
    // Data corrente
    const currentDate = new Date();
    const previousDate = new Date(currentDate);
    previousDate.setMonth(currentDate.getMonth() - 1);

    const currentYearMonth = currentDate.getFullYear() + '-' + String(currentDate.getMonth() + 1).padStart(2, '0');
    const previousYearMonth = previousDate.getFullYear() + '-' + String(previousDate.getMonth() + 1).padStart(2, '0');

    return <Select
        onChange={(e, v) => { onChange(v); }}
        variant="outlined"
        autoComplete="off"
        className="form-element-field form-element-field-select form-element-field-tiny form-element-cost-timeframe"
        startDecorator={<Icon icon='faCalendar' />}
        value={timeFrame}
    >
        <Option value="0">{t('Always')}</Option>
        <Option value={currentYearMonth}>{t('This month')}</Option>
        <Option value={previousYearMonth}>{t('Previous month')}</Option>
    </Select>
}

export default WidgetCostElementTimeFrame;