import React from 'react';
import { Box, List, ListItem } from '@mui/joy';

const CellGenericList = ({ type, value, field, limit = 1 }) => {

    if (!Array.isArray(value)) value = [value];

    const length = (value) ? Object.keys(value).length : 0;
    let more = null;
    
    if (length > limit) {
        more = <ListItem className={'cell-list-item-count cell-generics-list-item-count'}>
            <span>+{length - limit}</span>
        </ListItem>
    }

    return (
        <Box className={"list-element-cell cell-generics cell-generics-"+type}>

            {length > 0 && (
                <List className="cell-generics-list">
                    {value.map((item, k) => {
                        if (k >= limit || item == null) return;
                        return (
                            <ListItem key={k} className={'cell-generics-list-item'}>
                                <span className="name" title={item[field]}>{item[field]}</span>
                            </ListItem>
                        );
                    })}
                    {more}
                </List>
            )}
        </Box>
    )
    
}

export default CellGenericList;