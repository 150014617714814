import axios from 'axios';
import authHeader from './authHeader';
import { handleError, handleSuccess } from './handleResponse';

const endpointBase = process.env.REACT_APP_API_ENDPOINT

const createService = (endpointPart, multipart) => {


    const legalEntityId  = localStorage.getItem('filterByLegalEntity');

    const endpoint = endpointBase + '/' + endpointPart

    async function remove(id) {
        const axiosRequestOptions = {
            url: endpoint +  '/' + id + '?le=' + legalEntityId,
            method: 'DELETE',
            headers: authHeader()
        };

        return axios(axiosRequestOptions).then(handleSuccess).catch(handleError);
    }

    async function upload(files) {

        const formData = new FormData();
        formData.append("file", files[0]);

        let method = 'POST';

        const axiosRequestOptions = {
            url: endpoint,
            method: method,
            headers: authHeader(),
            data: formData

        };

        return axios(axiosRequestOptions).then(handleSuccess).catch(handleError)
        
    }

    async function save(data) {


        let method = 'POST';
        let urlId = '';

        if (data.id !== undefined && !multipart) {
            method = 'PUT';
            urlId = '/' + data.id;
        }

        urlId += '?le=' + legalEntityId

        const axiosRequestOptions = {
            url: endpoint + urlId,
            method: method,
            headers: authHeader(multipart),
            data: data

        };

        return axios(axiosRequestOptions).then(handleSuccess).catch(handleError)
        
    }

    async function getAll(filterModel= {}, sortModel= {}, page = '', per_page = '') {

        const axiosRequestOptions = {
            url: endpoint,
            method: 'GET',
            headers: authHeader(),
            // signal: controller.signal,
            params: {
                page: page,
                per_page: per_page,
                filters: filterModel,
                sort: sortModel,
                le: legalEntityId
            }
        };

        return axios(axiosRequestOptions).then(handleSuccess).catch(handleError);
    }

    async function getById(id) {

        const axiosRequestOptions = {
            url: endpoint + '/' + id,
            method: 'GET',
            headers: authHeader(),
            params: {
                le: legalEntityId
            }
        };

        const res = axios(axiosRequestOptions).then(handleSuccess).catch(handleError);

        return res
    }

    return {
        remove,
        save,
        getAll,
        getById,
        upload,
    }
}


export default createService