import React from "react"

import { Controller } from "react-hook-form";
import InputTextElement from "./InputTextElement";
import { useTranslation} from 'react-i18next';

const InputNumberControl = (props) => {
    const {t} = useTranslation();

    const { editing, required, name, control, label, autoFocus, placeholder, type = "number", hint, icon, haserror, disabled, minvalue, maxvalue, step, className = "", watchfunction = null } = props
    let { readonly } = props;
    if (!editing) readonly = true;


    const slotProps = {
        input: {
            min: minvalue,
            max: maxvalue,
            step: step
        },
    };


    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, value, name },
                fieldState: { error, invalid },
                formState,
            }) => {
                return (
                    <InputTextElement
                        id={name}
                        name={name}
                        value={value}
                        type={type}
                        label={label}
                        placeholder={placeholder}
                        icon={icon}
                        hint={hint}
                    
                        error={error}
                        invalid={(error) ? invalid : haserror}
                        editing={editing}
                        required={required}
                        autoFocus={autoFocus}
                        disabled={disabled}
                        readonly={readonly}
                        slotProps={slotProps}
                    
                        className={className}
                        onChange={onChange}
                        watchfunction={watchfunction}
                    />
                )
            }}
        />
    );
};


export default InputNumberControl;
