import * as Views from "./views";
import { NavigationList } from "../NavigationList"

const Module = {
    name: "cars",
    defaultRoutePath: "/cars/car",
    menuItem: {
        scope: "fleet",
        title: "vehicles",
        href: "/cars/car",
        icon: "faCar"
    },

    internalMenuItems: {
        activities: NavigationList["fleet"],
        wizards: []
    },
    routes: [
        {
            path: "/cars/car",
            protected: true,
            children: [
                {
                    index: true,
                    path: "",
                    Component: Views.Default,
                },
                {
                    path: "list",
                    Component: Views.List,
                },
            ],
        },
    ],
    drawerRoutes: [
        {
            path: "list",
            Component: Views.List,
        },
        {
            path: "detail",
            Component: Views.Detail,
        },
        
        {
            path: "install",
            Component: Views.Install,
        },
        {
            path: "create",
            Component: Views.Create,
        },
        {
            path: "personAssociation",
            Component: Views.PersonAssociation,
        },
        {
            path: "personDissociation",
            Component: Views.PersonDissociation,
        },
        {
            path: "poolAssociation",
            Component: Views.PoolAssociation,
        },
        {
            path: "poolDissociation",
            Component: Views.PoolDissociation,
        },
        {
            path: "serviceAssociation",
            Component: Views.ServiceAssociation,
        },
        {
            path: "serviceDissociation",
            Component: Views.ServiceDissociation,
        },
        {
            path: "contractAssociation",
            Component: Views.ContractAssociation,
        },
        {
            path: "contractDissociation",
            Component: Views.ContractDissociation,
        },
        {
            path: "plateManagement",
            Component: Views.PlateManagement,
        },
        {
            path: "statusManagement",
            Component: Views.StatusManagement,
        },
    ],
   
}

export default Module;
