import React from 'react';
import { ListItem, ListItemContent, Box } from "@mui/joy";
import { Button } from '../Button';

import { Tag, PersonAvatar } from '../Snippet';
import { useTranslation } from 'react-i18next'
import { useFormatter } from '../../utils';

const RelationshipPool = ({ k, v, editing, handleItemDetail, handleItemRemove }) => {
    const { t, i18n } = useTranslation();
    const { formatDate } = useFormatter();

    let trace_date
    if (typeof v?.traced_at != typeof undefined) {
        trace_date = formatDate(new Date(v.traced_at));
    } else {
        trace_date = '-';
    }

    return (
        <ListItem key={k} variant="outlined" className={"relationship-element"}>
            <ListItemContent sx={{ display: 'flex' }} className={"relationship-element-content"}>
                <Box className={"coupled"}>
                    <Box className="value">
                        {Array.isArray(v?.employments) && v.employments.map((employment, ke) => (
                            <PersonAvatar firstname={employment.person?.firstname} lastname={employment.person?.lastname} size="small" url={employment.person?.picture?.url} withname={(v.isPool === 'true') ? false : true } />
                        ))}
                    </Box>
                </Box>
                <Box className={"coupled"}>
                    <Box className="label">{(v.isPool === 'true') ? t('cars') : t('car')}</Box>
                    <Box className="value">
                        {Array.isArray(v?.cars) && v.cars.map((car, kc) => (
                            <span key={kc} className="plate">{car.plate}</span>
                        ))}
                    </Box>
                </Box>
                <Box className={"type"}>
                    <Tag color={ (v.isPool === 'true') ? 'danger' : 'secondary' } size="small" label={(v.isPool === 'true') ? t('pool_assignment') : t('direct_assignment')} />
                </Box>
            </ListItemContent>
            {editing && (<Button onClick={() => handleItemDetail(v)} icon="faPen" variant="plain" />)}
            {(editing && handleItemRemove) && (<Button onClick={() => handleItemRemove(v)} icon="faClose" variant="plain" />)}
        </ListItem>
    )
}

export default RelationshipPool;



