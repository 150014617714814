import React from "react"
import { DrawerButton } from "../../../../mtska-frontend-app-component";


const ImportButton = ({dataProviderName, moduleName, callback}) => {


    return (
        <DrawerButton
        drawerRoute={'imports/list'}
        params={{
            filters: {
                items: [{
                    operator: "equals",
                    field: "model",
                    value: dataProviderName,
                }]
            },
            currentDataProviderName: dataProviderName,
            moduleName:moduleName,
        }}
        callback={callback}
        label={"Import"} />
    );
};


export default ImportButton;
