import React from 'react';
import { ListItem, ListItemContent, Box } from "@mui/joy";
import { Button } from '../Button';

import { useTranslation } from 'react-i18next'
import { Tag, VehicleInstalledTermometer, ExpirationTermometer, LegalEntityAvatar } from '../Snippet';
import { useFormatter } from '../../utils';

const RelationshipCar = ({ k, v, editing, handleItemDetail, handleItemRemove }) => {
    const { t, i18n } = useTranslation();
    const { formatCurrency } = useFormatter();

    const tagcolors = {
        installed: "success",
        running: "success",
        ordered: "secondary",
        preleasing: "warning",
        substitute: "warning",
        archived: "danger"
    }

    const getType = (v) => {
        return v?.type ? v?.type.toLowerCase() : '';
    }

    const getStatus = (v) => {
        return v?.status ? v?.status.toLowerCase() : 'Running';
    }

    return (
        <>
            <ListItem key={k} variant="outlined" className={"relationship-element"}>
                {/* { <textarea>{ JSON.stringify(v)}</textarea> } */}
                <ListItemContent className={"relationship-element-content vehicle-type-" + getType(v) + " vehicle-status-" + getStatus(v)}>
                    <Box className={"makeandorder"}>
                        <span className={"make"} title={(v?.setup?.make || '') + " " + (v?.setup?.model || '-') + " " + (v?.setup?.name || '-')}>{(v?.setup?.make || '-') + " " + (v?.setup?.model || '-') + " " + (v?.setup?.name || '')}</span>
                        {v.pool?.isPool === 'true' ? t('pool_assignment') : t('direct_assignment')}

                    </Box>
                    <Box className={"plate"}>
                        {v?.plate}
                    </Box>
                    {
                        v?.contract ? (
                            <LegalEntityAvatar name={v?.contract?.renter?.name} url={v?.contract?.renter?.logo?.url} withname={true} detail={formatCurrency(v?.fbMonthly) + ' ' + v?.contract?.orderIdentifier} />
                        ) : (
                            <LegalEntityAvatar size="small" withname={true} error={t('No contract')} />
                        )
                    }
                    <Box className={"status"}>
                        <Tag color={(tagcolors[getStatus(v)])} size="small" label={t(getStatus(v))} />
                    </Box>

                    {/* <Box className="pool coupled">
                        <Box className="label">{v.pool?.isPool === 'true' ? t('pool_assignment') : t('direct_assignment')}</Box>
                        <Box className="value">{v.pool?.name}</Box>
                    </Box> */}
                    {/* <Box className={"analysis analysis-" + getStatus(v)}>
                        {(v?.status === 'INSTALLED' && v?.type === 'RENT') && (
                            <VehicleInstalledTermometer contractStartDate={v?.contractStartDate} contractEndDate={v?.contractEndDate} contractTotalKM={v?.contractTotalKM} lastTraceKMValue={v?.lastTraceKm?.value} lastTraceKMDate={v?.lastTraceKm?.traceDay} />
                        )}
                        {(v?.status === 'ORDERED' && v?.type === 'RENT') && (
                            <ExpirationTermometer installationDate={v?.orderOrderDate} expirationDate={v?.expectedArrivalDay} />
                        )}
                    </Box> */}
                </ListItemContent>

                {editing && (<Button onClick={() => {
                    handleItemDetail(v);
                }} icon="faPen" variant="plain" />)}
                {editing && handleItemRemove && (<Button onClick={() => handleItemRemove(v)} icon="faClose" variant="plain" />)}
            </ListItem>
        </>
    )
}


export default RelationshipCar


