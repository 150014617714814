import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, SectionTitleEmbedded, Tag } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';

const dataProviderName = 'cars/car';
const drawerRouteBase = 'cars';


const CarsOfPerson = ({ item }) => {

    const { t } = useTranslation();

    const [cap, setCap] = useState();

    // DATA PROVIDER
    const { items, loadAll: loadAllCars } = useDataProvider(dataProviderName);
    const { item: config } = useDataProvider('configs/config');

    useEffect(() => {
        if (typeof config?.data?.capabilities === 'undefined') return;
        setCap(config.data.capabilities)
    }, [config])

    const [detailConfig, setDetailConfig] = useState();
    const [modelName, setModelName] = useState();
    useEffect(() => {
        if (typeof config?.data?.modules === 'undefined') return;
        if (typeof config.data.modules[dataProviderName] === 'undefined') return;
        //setDetailConfig(config.data.modules[dataProviderName].detailConfig);
        setDetailConfig({
            "tabs": [
                {
                    "name": "cars",
                    "fieldsets": [
                        {
                            "name": "cars",
                            "fields": {
                                "cars": {
                                    "type": "relationship",
                                    "relation": {
                                        "drawerRoute": "cars",
                                        "inverseRelation": "pool",
                                        "inverseRelationType": "relationship",
                                        "relationAssociator": "persons/carAssociation",
                                        "relationDissociator": "persons/carDissociation",
                                        "limit": 1000
                                    },
                                    "view": "car",
                                }
                            }
                        }
                    ]
                }
            ],
            "validation": []
        });

        setModelName(config.data.modules[dataProviderName].modelName);
    }, [config, dataProviderName]);

    // load from dataProvider
    let loaded = false;
    useEffect(() => {
        if (loaded) return;

        if (!item?.id) return
        loadAllCars({
            "items": [
                {
                    "field": "pool.employments.person.id",
                    "operator": "equalsHas",
                    "value": item.id
                }
            ]
        })
        loaded = true;
    }, [])

    return <>
        {
            items &&
            <>
                <DetailComponent
                    values={{ cars : items  }}
                    modelName={modelName}
                    detailConfig={detailConfig}
                    save={() => {}}
                    saving={false}
                    errors={{}}
                    errorMessage={null}
                    isEmbedded={true}
                />
            </>
        }
    </>
}

export default CarsOfPerson;