import React from "react";
import { Box } from "@mui/joy";
import { useString } from "../../utils/String";

const PersonAvatar = ({ firstname, lastname, size = "default", url = null, withname = false, detail = false}) => {

    const { firstCharacter, toColor } = useString();

    return (
        <Box className={"person-avatar-wrapper person-avatar-withname-" + withname}>
            <Box className={"person-avatar person-avatar-" + size}>
                {url ? (
                    <img src={url} title={firstname + " " + lastname}/>
                ) : (
                    <img src="https://thispersondoesnotexist.com" title={firstname + " " + lastname}/>
                )}
                {/* {!url && (
                    <span className="name" title={firstname + " " + lastname}>{firstCharacter(firstname) + firstCharacter(lastname)}</span>
                )} */}
            </Box>
            {withname === true && (
                <Box className={"person-name"+(detail && " hasdetail")}>
                    <span className="fullname" title={firstname + " " + lastname}>{firstname + " " + lastname}</span>
                    {
                        detail && (
                            <span className="detail">{(detail)}</span>
                        )
                    }
                </Box>
            )}
            {withname === 'initial' && (
                <Box className={"person-name"}>
                    <span className="name" title={firstname + " " + lastname}>{firstCharacter(firstname) + firstCharacter(lastname)}</span>
                </Box>
            )}
        </Box>
    )
}

export default PersonAvatar;