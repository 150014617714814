import React from "react";
import { Box } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Tag } from "../Snippet";

const InfoHeaderWizard = ({ view, title, subtitle }) => {
    const { t } = useTranslation();

    return (
        <Box className={"info-header info-header-" + view}>
            <Box className={"info-wrapper"}>

                <Tag size="small" color="secondary">{t('WIZARD')}</Tag>
                <span className="title">{title}</span>
                <span className="subtitle">{subtitle}</span>

            </Box>
        </Box>
    )
}

export default InfoHeaderWizard;