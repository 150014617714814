import React from "react";
import { useTranslation } from 'react-i18next';
import { useFormatter } from '../../utils';

const WidgetCostElementRows = ({ costbreakdown }) => {
    const { t } = useTranslation();
    const { formatCurrencyVariable } = useFormatter();

    if (typeof costbreakdown == 'undefined' || !Array.isArray(costbreakdown)) return;

    if (costbreakdown.length == 0) return <tr><td colSpan={3} style={{'textAlign':'center', 'fontWeight':'200', 'lineHeight':'100px'}}>{t('No cost found')}</td></tr>
    return costbreakdown.map((cost) => {
        return <tr>
            <td>{t(cost.type)}</td>
            <td>{cost.costType == 'work_cost' && formatCurrencyVariable(cost.total / 100, cost.currency)}</td>
            <td>{cost.costType == 'personal_cost' && formatCurrencyVariable(cost.total / 100, cost.currency)}</td>
        </tr>
    })
}

export default WidgetCostElementRows;