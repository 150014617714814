import React from "react";
import { Box } from "@mui/joy";

import { useTranslation } from 'react-i18next'
import Termometer from "./Termometer";
import { useFormatter } from "../../utils";

const TraceKmTermometer = ({ tracedate }) => {
    const { t, i18n } = useTranslation();
    const { formatDistance, formatDate } = useFormatter();
    function addDays(date, days) {
        let result = new Date(date);
        result.setDate(result.getDate() + days);
        return result;
    }



    const traced        = new Date(tracedate);
    const td            = new Date();
    const nexttraced    = addDays(traced, parseInt(process.env.REACT_APP_TRACE_KM_MAX_MISSING_DAYS));

    const awaitenlapsed = Math.floor((td - traced) / (1000 * 60 * 60 * 24));
    const awaitnext     = Math.floor((nexttraced - traced) / (1000 * 60 * 60 * 24));

    const awaitenlapsedpercentage = awaitenlapsed / awaitnext;

    let color = "success";
    if (awaitenlapsedpercentage >= 1) {
        color = "danger"
    } else if (awaitenlapsedpercentage >= 0.7) {
        color = "warning"
    }

    return (
        <Box className="general-termometer">
            <Termometer percentage={awaitenlapsedpercentage} color={color} hint={t("Next detection expected at") + " " + formatDate(nexttraced)} hintposition="top" />
        </Box>
    )
}

export default TraceKmTermometer;