
export const useInfocarDataMapper = () => {

    const getGearbox = (sourcevalue) => {
        let destinationvalue;
        switch (sourcevalue.trim()) {
            case 'manual':
            case 'meccanico':
                destinationvalue = 'manual';
                break;
            case 'automatic':
            case 'automatico':
            case "meccanico sequenziale con possibile funzionamento automatico a contr.elettronico":
            case 'automatico a controllo elettronico, con possibile utilizzo manuale sequenziale,':
            case 'automatico a controllo elettronico':
                destinationvalue = 'automatic';
                break;
            case 'cvt':
            case 'automatico continuo con rotismo epicicloidale':
            case 'a variazione continua CVT (pulegge a diametro variabile unite da cinghia)':
            case 'automatico CVT a contr.elettronico, con possibile utilizzo manuale sequenziale,':
                destinationvalue = 'cvt';
                break;
            case 'semi-automatic':
            case 'meccanico semiautomatico sequenziale a controllo elettronico':
                destinationvalue = 'semi-automatic';
                break;
            case 'direct-drive':
            case 'presa diretta tra albero motore e riduzione finale':
            case 'assente: presa diretta tra albero motore e differenziale':
            case 'assente: presa diretta tra albero motore e riduzione finale':
                destinationvalue = 'direct-drive';
                break;
            default:
                destinationvalue = 'unknown';
                console.debug('MAPPING GEARBOX MISSING', sourcevalue);
                break;
        }
        return destinationvalue
    }

    const getBodytype = (sourcevalue) => {
        let destinationvalue;
        switch (sourcevalue.trim()) {
            case 'hatchback':
            case 'berlina due volumi':
            case 'Berlina 2V':
                destinationvalue = 'hatchback';
                break;
            case 'sedan':
            case 'berlina tre volumi':
            case 'Berlina 3V':
                destinationvalue = 'sedan';
                break;
            case 'suv':
                destinationvalue = 'suv';
                break;
            case 'station-wagon':
            case 'station wagon':
                destinationvalue = 'station-wagon';
                break;
            case 'off-road':
            case 'fuoristrada chiusa':
                destinationvalue = 'off-road';
                break;
            case 'city-car':
                destinationvalue = 'city-car';
                break;
            case 'micro-car':
                destinationvalue = 'micro-car';
                break;
            case 'cabrio':
            case 'aperta':
            case 'fuoristrada aperta':
                destinationvalue = 'cabrio';
                break;
            case 'mpv':
            case 'multispazio':
            case 'monovolume':
                destinationvalue = 'mpv';
                break;
            case 'van':
            case 'furgone':
                destinationvalue = 'van';
                break;
            case 'minivan':
                destinationvalue = 'minivan';
                break;
            case 'coupe':
            case 'coupé':
                destinationvalue = 'coupe';
                break;
            case 'pickup':
                destinationvalue = 'pickup';
                break;
            default:
                console.debug('MAPPING BODY TYPE MISSING', sourcevalue);
                destinationvalue = 'unknown';
                break;
        }
        return destinationvalue
    }

    const getEnvironmentalClass = (sourcevalue) => {
        let destinationvalue;
        switch (sourcevalue.trim()) {
            case 'Euro1':
            case 'Euro 1':
            case 'Euro 1 (direttiva CEE 91/441)':
                destinationvalue = 'Euro1';
                break;
            case 'Euro2':
            case 'Euro 2':
                destinationvalue = 'Euro2';
                break;
            case 'Euro3':
            case 'Euro 3':
                destinationvalue = 'Euro3';
                break;
            case 'Euro4':
            case 'Euro 4':
                destinationvalue = 'Euro4';
                break;
            case 'Euro5':
            case 'Euro 5':
            case 'Euro5 (715/2007-692/2008)':
                destinationvalue = 'Euro5';
                break;
            case 'Euro6':
            case 'Euro 6':
            case 'Euro6 (715/2007-692/2008)':
                destinationvalue = 'Euro6';
                break;
            case 'Euro6d':
            case 'Euro6.d tmp (2016/427) e seguenti':
                destinationvalue = 'Euro6d';
                break;
            case 'ZEV':
            case 'Zero Emission Vehicles Europe':
                destinationvalue = 'ZEV';
                break;
            default:
                console.debug('MAPPING ENVIRONMENTAL CLASS MISSING', sourcevalue);
                destinationvalue = 'unknown';
                break;
        }
        return destinationvalue
    }

    const getDrivetrain = (sourcevalue) => {
        let destinationvalue;

        switch (sourcevalue.trim()) {
            case 'front-wheel-drive':
            case 'Anteriore':
                destinationvalue = 'front-wheel-drive';
                break;
            case 'rear-wheel-drive':
            case 'Posteriore':
                destinationvalue = 'rear-wheel-drive';
                break;
            case 'all-wheel-drive':
            case 'Integrale':
                destinationvalue = 'all-wheel-drive';
                break;
            case 'four-wheel-drive':
                destinationvalue = 'four-wheel-drive';
                break;
            case 'selectable-four-wheel-drive':
            case 'Anteriore, Posteriore inseribile':
                destinationvalue = 'selectable-four-wheel-drive';
                break;
            default:
                console.debug('MAPPING DRIVETRAIN MISSING', sourcevalue);
                destinationvalue = 'unknown';
                break;
        }

        return destinationvalue
    }


    const getFueltype = (sourcevalue) => {
        let destinationvalue;
        sourcevalue = sourcevalue.join('|');
        switch (sourcevalue.trim()) {
            case 'petrol':
            case '|B|':
                destinationvalue = 'petrol';
                break;
            case 'diesel':
            case '|D|':
                destinationvalue = 'diesel';
                break;
            case 'hev':
            case 'Full Hybrid (HEV)|B|E':
                destinationvalue = 'hev';
                break;
            case 'phev':
            case 'Plug-In Hybrid (PHEV)|B|E':
                destinationvalue = 'phev';
                break;
            case 'mhev':
            case 'Ibrida (MHEV)|B|E':
            case 'Mild/Micro Ibrida (MHEV)|B|E':
            case 'Mild/Micro Ibrida (MHEV)|D|E':
                destinationvalue = 'mhev';
                break;
            case 'fhev':
                destinationvalue = 'fhev';
                break;
            case 'erev':
                destinationvalue = 'erev';
                break;
            case 'bev':
            case '|E|':
            case '|E|E':
                destinationvalue = 'bev';
                break;
            case '|G|B':
            case '|B|G':
                destinationvalue = 'petrol-lpg';
                break;
            case '|G|':
            case 'lpg':
                destinationvalue = 'lpg';
                break;
            case 'cng':
                destinationvalue = 'cng';
                break;
            default:
                console.debug('MAPPING FUELTYPE MISSING', sourcevalue);
                destinationvalue = 'unknown';
                break;
        }

        return destinationvalue
    }

    return {
        getGearbox,
        getEnvironmentalClass,
        getBodytype,
        getDrivetrain,
        getFueltype
    }

}