import { useTranslation } from 'react-i18next'

export const useFormatter = () => {
    const { t, i18n } = useTranslation();

    const dateformatter = Intl.DateTimeFormat(i18n.resolvedLanguage);

    const distanceformatter = Intl.NumberFormat(i18n.resolvedLanguage, {
        style: 'unit',
        unit: 'kilometer',
        unitDisplay: 'short',
        maximumFractionDigits: 0
    });

    const numberformatter = Intl.NumberFormat(i18n.resolvedLanguage);
    const percentformatter = Intl.NumberFormat(i18n.resolvedLanguage, { style: "percent" })
    const currencyformatter = Intl.NumberFormat(i18n.resolvedLanguage, { style: 'currency', currency: 'EUR' })

    const byteFormatter = Intl.NumberFormat(i18n.resolvedLanguage, {
        notation: "compact",
        style: "unit",
        unit: "kilobyte",
        unitDisplay: "narrow",
    });

    const formatDate = (string) => {
        let formatted = '-';
        if (string) {
            try {
                formatted = dateformatter.format(new Date(string));
            } catch (e) {
                formatted = <em>!{JSON.stringify(string)} </em>;
            }
        }
        return formatted;
    }

    const formatDateForInput = (string) => {
        let formatted = '';
        if (string) {
            try {
                formatted = new Date(string).toLocaleDateString('en-CA');
            } catch (e) {
                // do nothing
            }
        }
        return formatted;
    }

    const formatDistance = (string) => {
        let formatted = '-';
        if (typeof string != typeof undefined && string !== null) {
            try {
                formatted = distanceformatter.format(string);
            } catch (e) {
                formatted = string
            }
        }
        return formatted;
    }

    const formatNumber = (string) => {
        let formatted = '';
        if (typeof string != typeof undefined && string !== null) {
            try {
                formatted = numberformatter.format(string);
            } catch (e) {
                formatted = string
            }
        }
        return formatted;
    }

    const formatPercentage = (string) => {
        let formatted = '';
        if (typeof string != typeof undefined && string !== null) {
            try {
                formatted = percentformatter.format(string);
            } catch (e) {
                formatted = string
            }
        }
        return formatted;
    }

    const formatCurrency = (string) => {
        let formatted = '';
        if (typeof string != typeof undefined && string !== null) {
            try {
                formatted = currencyformatter.format(string);
            } catch (e) {
                formatted = string
            }
        }
        return formatted;
    }

    const formatCurrencyVariable = (string, currency) => {
        let cf;
        switch (currency) {
            case 'EUR':
                cf = currencyformatter;
                break;
            case 'USD':
                cf = Intl.NumberFormat(i18n.resolvedLanguage, { style: 'currency', currency: 'USD' });
                break;
            case 'GBP':
                cf = Intl.NumberFormat(i18n.resolvedLanguage, { style: 'currency', currency: 'GBP' });
                break;
            case 'CHF':
                cf = Intl.NumberFormat(i18n.resolvedLanguage, { style: 'currency', currency: 'CHF' });
                break;
        }

        let formatted = '';
        if (typeof string != typeof undefined && string !== null) {
            try {
                formatted = cf.format(string);
            } catch (e) {
                formatted = string
            }
        }
        return formatted;
    }

    const formatBytes = (string) => {
        let formatted = '';
        if (typeof string != typeof undefined && string !== null) {
            try {
                formatted = byteFormatter.format(string);
            } catch (e) {
                formatted = string
            }
        }
        return formatted;
    }

    return {
        formatDate,
        formatDateForInput,
        formatDistance,
        formatNumber,
        formatPercentage,
        formatCurrency,
        formatCurrencyVariable,
        formatBytes
    }

};
