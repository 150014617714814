import React from 'react';
import { ListItem, ListItemContent, Box } from "@mui/joy";
import { Button } from '../Button';
import { Tag } from '../Snippet';
import { useTranslation } from 'react-i18next'

const RelationshipTyreSet = ({ k, v, editing, handleItemDetail, handleItemRemove }) => {
    const { t, i18n } = useTranslation();

    const tagcolors = {
        installed: "success",
        ordered: "secondary",
        stored: "warning",
        terminated: "danger"
    }

    return (
        <ListItem key={k} variant="outlined" className={"relationship-element"}>
            <ListItemContent className={"relationship-element-content tyreset-type-" + v?.type?.toLowerCase() + " tyreset-status-" + v?.status?.toLowerCase()}>
                <Box className="quantity">
                    {v?.number}
                </Box>
                <Box className="coupled">
                    <Box className="label">{v?.type}</Box>
                    <Box className="value">{v?.category}</Box>
                </Box>
                <Box className="status">
                    <Tag color={(tagcolors[v?.status])} size="small" label={t(v?.status)} />
                </Box>
                <Box className={v.status == 'stored' ? "coupled" : "coupled norightborder"}>
                    <Box className="label">{t('Brand')}</Box>
                    <Box className="value">{v?.make + " " + v?.model}</Box>
                </Box>
                {v.status == 'stored' && (
                    <Box className="coupled norightborder">
                        <Box className="label">{t('Storage location')}</Box>
                        <Box className="value">{v?.storageLocation}</Box>
                    </Box>
                )}
            </ListItemContent>

            {editing && (<Button onClick={() => handleItemDetail(v)} icon="faPen" variant="plain" />)}
            {(editing && handleItemRemove) && (<Button onClick={() => handleItemRemove(v)} icon="faClose" variant="plain" />)}
        </ListItem>
    )
}


export default RelationshipTyreSet


