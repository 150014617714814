import React from 'react';
import { ListItem, ListItemContent, Box } from "@mui/joy";
import { Button } from '../Button';

import { useTranslation } from 'react-i18next'
import { Icon } from '../../assets';
import { useFormatter } from '../../utils';

const RelationshipFileGeneric = ({ k, v, editing, handleItemDetail, handleItemRemove }) => {
    const { t, i18n } = useTranslation();
    const { formatBytes } = useFormatter();

    const printableExtensions = ['JPG', 'JPEG', 'PNG', 'SVG', 'WEBP'];
    const isPrintable = () => {
        const ucExtension = v?.extension && v?.extension.toUpperCase();
        return printableExtensions.includes(ucExtension);
    }

    return (
        <ListItem key={k} variant="outlined" className={"relationship-element"}>
            <ListItemContent className={"relationship-element-content"}>
                <Box className={"icon-file icon-" + (isPrintable() ? 'preview' : 'type')}>
                    {
                        isPrintable() && (
                            <img src={v?.url} className="filepreview" />
                        )
                    }
                    {
                        !isPrintable() && (
                            <>
                                <Icon icon="faFile" className="fileicon" />
                                <Box className="extension">{v?.extension}</Box>
                            </>
                        )
                    }
                </Box>
                <Box className="coupled meta">
                    <span className="label">{v?.filename}</span>
                    <span className="value">{formatBytes(v?.size / 1024)}</span>
                </Box>
            </ListItemContent>

            {editing && (<Button onClick={() => handleItemDetail(v)} icon="faPen" variant="plain" />)}
            {(editing && handleItemRemove) && (<Button onClick={() => handleItemRemove(v)} icon="faClose" variant="plain" />)}
        </ListItem>
    )
}

export default RelationshipFileGeneric



