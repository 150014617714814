import { Default, Detail, List,  Create } from "./views";
import { NavigationList } from "../NavigationList"



const Module = {
    name: "locations",
    defaultRoutePath: "/legalentities/location",
    menuItem: {
        scope: "legalentities",
        title: "locations",
        href: "/legalentities/location",
        icon: "faBuilding",
        hidden: true
    },

    internalMenuItems: {
        activities: NavigationList["legalentities"],
        wizards: []
    },
    routes: [
        {
            path: "/legalentities/location",
            protected: true,
            children: [
                {
                    index: true,
                    path: "",
                    Component: Default,
                },
                {
                    path: "list",
                    Component: List,
                },
            ],
        },
    ],
    drawerRoutes: [
        {
            path: "list",
            Component: List,
        },
        {
            path: "detail",
            Component: Detail,
        },
        
        {
            path: "create",
            Component: Create,
        },
    ],
    
}

export default Module;
