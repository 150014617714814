import createConstants from './constants';


const constants = createConstants();

const initialStatus = {
    widgets: [],
    items: [],
    total: 0,
    item: null,
    modelValidation:{},
    importModel: {},
    tableConfig: {},
    formConfig: {},
    detailConfig: {},
    loading: false,
    saving: false,
    error: false,
};


export function reducer(state = {}, action) {
    // console.log("reducer action", action);
    // console.log("reducer state", state);

    if (typeof action.dataProviderName === 'undefined') {
        return state;
    }

    const tmp = state[action.dataProviderName];
    const dataProviderState = (tmp === 'undefined') ? initialStatus : tmp;


    // return state;


    const newState = { ...state };
    newState[action.dataProviderName] = { ...dataProviderReducer(dataProviderState, action) }
    return newState;

}

export function dataProviderReducer(state = initialStatus, action) {



    switch (action.type) {

        case constants.SAVE_REQUEST:
            return {
                ...state,
                errors: {},
                errorMessage: null,
                success: false,
                saving: true,
            };

        case constants.SAVE_SUCCESS:
            return {
                ...state,
                item: action.item,
                loading: false,
                saving: false,
                success: true,
                errors: {},
                errorMessage: null,
            };

        case constants.SAVE_FAILURE:
            return {
                ...state,
                success: false,
                saving: false,
                errors: action.errors,
                errorMessage: action.message,
            };


        case constants.DELETE_REQUEST:
            return {
                ...state,
                loading: true,
                saving: false,
            };

        case constants.DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                saving: false,
            };

        case constants.DELETE_FAILURE:
            return {
                ...state,
                loading: false,
                saving: false,
            };


        case constants.GETALL_REQUEST:
            return {
                ...state,
                items: [],
                loading: true,
                saving: false,
            };

        case constants.GETALL_SUCCESS:
            return {
                ...state,
                widgets: action.items.widgets,
                items: action.items.data,
                tableConfig: action.items.tableConfig,
                detailConfig: action.items.detailConfig,
                total: action.items.meta.total,
                loading: false,
                saving: false,

            };

        case constants.GETALL_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
                saving: false,
            };


        case constants.GETONE_REQUEST:
            return {
                ...state,
                item: {
                    data: {}
                },
                errors: {},
                errorMessage: null,
                saving: false,
                loading: true
            };

        case constants.GETONE_SUCCESS:
            return {
                ...state,
                item: action.item,
                loading: false,
                saving: false,

            };

        case constants.GETONE_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
                saving: false,

            };

        case constants.RESET_REQUEST:
            return {
                ...state,
                items: [],
                loading: true,
                saving: false,
            };

        case constants.RESET_SUCCESS:
            return initialStatus;

        case constants.RESET_FAILURE:
            return {
                ...state,
                error: action.error,
                loading: false,
                saving: false,
            };

        default:
            return state;
    }
}