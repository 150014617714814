import React, { useState } from 'react'
import { Box } from '@mui/joy'
import { IconButton } from '../../assets'
import { GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarFilterButton, GridToolbarQuickFilter } from '@mui/x-data-grid'
import { Button } from '../Button'
const ListToolbar = ({ leftButtons = [], rightButtons = [] }) => {

    const [settingsOpened, setSettingsOpened] = useState(false)
    const handleSettingsToggle = () => {
        setSettingsOpened(!settingsOpened)
    }

    return (
        <GridToolbarContainer className="list-toolbar">

            <Box className="list-toolbar-secondary list-toolbar-filter">
                <GridToolbarQuickFilter sx={{ marginLeft: "auto" }} />
                {leftButtons.map((v, k) =>
                    <Box key={k}>{v}</Box>
                )}
            </Box>

            <Box className="list-toolbar-primary">

                {settingsOpened ? (
                    <GridToolbarContainer>
                        <GridToolbarColumnsButton />
                        <GridToolbarFilterButton />
                    </GridToolbarContainer>
                ) : (
                    rightButtons.map((v, k) =>
                        <Box key={k}>{v}</Box>
                    )
                )}

                <IconButton onClick={handleSettingsToggle} className="list-toolbar-activator" icon="faEllipsisVertical" />
            </Box>
        </GridToolbarContainer>

    )
}

export default ListToolbar;
