import React, { useState } from 'react';

import { Box, Chip } from '@mui/joy';
import { Text } from '../Typography';
import { Icon } from '../../assets';
import { default as ReactApexChart } from 'react-apexcharts'
import { useFormatter } from '../../utils';

const Scorecard = ({ title, score, delta, series, scoreFormatStyle, scoreFormatUnit, scoreFormatCurrency }) => {
    const { formatNumber } = useFormatter();

    const [color, setColor] = useState((delta >= 0) ? "#1F7A1F" : "#C41C1C");
    const [options, setOptions] = useState({
        chart: {
            width: '128px',
            height: '64px',
            toolbar: {
                autoSelected: "pan",
                show: false
            },
            zoom: {
                enabled: false,
            },
            sparkline: {
                enabled: true
            }
        },
        xaxis: {
            show: false,
            labels: {
                show: false
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            }
        },
        yaxis: {
            show: false,
            labels: {
                show: false
            },
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            }
        },
        colors: [color],
        stroke: {
            width: 2,
            curve: 'smooth'
        },
        grid: {
            show: false,
        },
        legend: {
            show: false,
        },
        fill: {
            type: "gradient",
            gradient: {
                opacityFrom: 0.4,
                opacityTo: 0,
                stops: [0, 100],
                type: "vertical",
                shadeIntensity: 0.5,
            }
        },
        dataLabels: {
            enabled: false,
        }
    });

    let format = {
        style: scoreFormatStyle
    };

    if (scoreFormatStyle == 'currency') format.currency = (scoreFormatCurrency) ? scoreFormatCurrency : 'EUR';
    if (scoreFormatStyle == 'unit' && scoreFormatUnit) format.unit = scoreFormatUnit;

    return (
        <Box className="mtksa-chart mtksa-chart-scorecard">
            <Box className="scorewrapper">
                <Text level="title-sm" className="title">{title}</Text>
                <Text level="title-lg" className="score">{formatNumber(score)}</Text>
                <Chip
                    className={(delta >= 0) ? "delta positive" : "delta negative"}
                    startDecorator={(delta >= 0) ? <Icon icon="faArrowUp" /> : <Icon icon="faArrowDown" />}
                >
                    {delta}%
                </Chip>
            </Box>
            <Box className="chartwrapper">
                <ReactApexChart
                    type="area"
                    options={options}
                    series={series}
                />
            </Box>
        </Box>
    );
}

export default Scorecard;