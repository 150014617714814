
export const NavigationList = {
    fleet: [
        {
            title: "vehicles",
            href: "/cars/car",
        },
        {
            title: "assignments",
            href: "/cars/pool",
        },
        {
            title: "setups",
            href: "/cars/setup",
        },
        {
            title: "contracts",
            href: "/cars/contract",
        },
        // {
        //     title: "orders",
        //     href: "/cars/order",
        // },
        {
            title: "tracekms",
            href: "/cars/tracekm",
        },
        // {
        //     title: "tyresets",
        //     href: "/cars/tyreset",
        // },
        // {
        //     title: "policies",
        //     href: "/cars/policy",
        // },
        {
            title: "costs",
            href: "/cars/cost",
        },
        {
            title: "services",
            href: "/cars/service",
        },
    ],

    legalentities: [
        {
            title: "legalentities",
            href: "/legalentities/legalentity",
        },
        {
            title: "businessunits",
            href: "/legalentities/businessunit",
        },
        {
            title: "costcenters",
            href: "/legalentities/costcenter",
        },
        {
            title: "profitcenters",
            href: "/legalentities/profitcenter",
        },
        {
            title: "locations",
            href: "/legalentities/location",
        },
        // {
        //     title: "renters",
        //     href: "/legalentities/renter",
        // },
    ],


    people: [
        {
            title: "people",
            href: "/people/person",
        },
        // {
        //     title: "drivers",
        //     href: "/people/driver",
        // },
        // {
        //     title: "employments",
        //     href: "/people/employment",
        // },
        // {
        //     title: "users",
        //     href: "/people/user",
        // },
        {
            title: "roles",
            href: "/people/role",
        },
        {
            title: "permissions",
            href: "/people/permission",
        },
    ],
    bis: [
        {
            title: "Business Intelligence",
            href: "/bis/biviewer",
        },
        {
            title: "BI Configurations",
            href: "/bis/configuration",
        },
    ],
    documents: [
        {
            title: "documents",
            href: "/documents/document",
        },
    ],
}

