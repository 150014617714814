export const useFormVisibilityHandler = () => {

    const verifyCondition = (conditiongroup, watch, getValues) => {
        let visible = true;
        let innerConditions = [];
        Array.isArray(conditiongroup.visibilitycondition) && conditiongroup.visibilitycondition.map((rule, k) => {
            if (Array.isArray(rule)) {
                innerConditions.push(verifyInnerRule(rule, watch, getValues))
            } else {
                innerConditions.push(rule)
            }
        })


        if (innerConditions.length == 1) {
            return innerConditions[0];
        } else if (innerConditions.length == 3 && innerConditions[1] == 'and') {
            //console.debug('verifyCondition 3 innerConditions', conditiongroup.name, conditiongroup.visibilitycondition, innerConditions);
            return innerConditions[0] && innerConditions[2];
        } else if (innerConditions.length == 3 && innerConditions[1] == 'or') {
            return innerConditions[0] || innerConditions[2];
        }

        return visible;
    }

    const verifyInnerRule = (rule, watch, getValues) => {
        let visible = true;
        if (rule[2] == 'null') rule[2] = null


        switch (rule[1]) {
            case '=':
                if (watch(rule[0]) != rule[2]) {
                    visible = false;
                }
                break;
            case '!=':
                if (watch(rule[0]) == rule[2]) {
                    visible = false;
                }
                break;
            case 'in':
                if (!rule[2].includes(watch(rule[0]))) {
                    visible = false;
                }
                break;
            case 'nin':
                if (rule[2].includes(watch(rule[0]))) {
                    visible = false;
                }
                break;
            case 'countEquals':
                if (watch(rule[0]).length != parseInt(rule[2])) {
                    visible = false;
                }
                break;
            case 'countLess':
                if (watch(rule[0]).length < parseInt(rule[2])) {
                    visible = false;
                }
                break;
            case 'countMore':
                if (watch(rule[0]).length > parseInt(rule[2])) {
                    visible = false;
                }
                break;
        }

        //console.debug('verifyInnerRule rule', rule, rule[0], watch(rule[0]), (visible ? 'visible' : 'non visible'));
        return visible;
    }
    return {
        verifyCondition
    }
}