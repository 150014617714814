import React, { useState, useEffect } from "react"

import { Box, Chip } from '@mui/joy';
import { Text } from '../Typography';
import { Icon } from '../../assets';
import { useFormatter } from '../../utils';
import { useTranslation } from 'react-i18next';
import { useDataProvider } from '../../../mtska-frontend-data-provider';

const KPI = ({ title, dataProviderName, icon}) => {
    const { formatNumber } = useFormatter();
    const { t } = useTranslation();

    const { items, loadAll, reset } = useDataProvider(dataProviderName);
    const [score, setScore] = useState(parseInt(Math.random() * 10000));
    const [delta, setDelta] = useState();

    // useEffect(() => {
    //     loadAll();
    // }, []);

    // useEffect(() => {
    //     console.debug('items ricevuti da '+dataProviderName, items);
    //     setScore(items.count);
    // }, [items])

    return (
        <Box className="mtksa-chart mtksa-chart-kpi">
            <Box className="iconwrapper">
                <Icon icon={icon} family="fal"></Icon>
            </Box>
            <Box className="scorewrapper">
                <Text level="title-sm" className="title">{t(title)}</Text>
                <Text level="title-lg" className="score">
                    {
                        (score !== null) ? formatNumber(score) : "..."
                    }
                </Text>
                {
                    delta && (
                        <Chip
                            className={(delta >= 0) ? "delta positive" : "delta negative"}
                            startDecorator={(delta >= 0) ? <Icon icon="faArrowUp" /> : <Icon icon="faArrowDown" />}
                        >
                            {delta}%
                        </Chip>
                    )
                }
            </Box>
        </Box>
    );
}

export default KPI;