//// LOCAL
import './mtska-frontend-app-scaffolding/styles/App/App.scss';
import { App } from './mtska-frontend-app-scaffolding';

import {
    // EmptyModule,
    DocumentModule,
    CarModule,
    ContractModule,
    LegalEntityModule,
    BiViewerModule, 
    AuthModule,
    // BiModule,
    PersonModule,
    // DriverModule,
    UserModule,
    RoleModule,
    PermissionModule,
    EmptyModule,
    // OrderModule,
    BusinessUnitModule,
    CostCenterModule,
    ProfitCenterModule,
    LocationModule,
    EmploymentModule,
    // PolicyModule,
    TraceKmModule,
    // TyreSetModule,
    SetupModule,
    CostModule,
    // RenterModule,
    PoolModule,
    ServiceModule,
} from './mtska-frontend-modules';
import { ImportModule } from './mtska-frontend-modules/modules/Import';



const AppExecutive = () => {
    const config = {
        title: process.env.REACT_APP_SHORT_NAME,
        modules: [
            ImportModule,
            CarModule,
            LegalEntityModule,
            PersonModule,
            // AreaModule,
            // OrganizationModule,
            // BiViewerModule,
            AuthModule,
            // OrderModule,
            // PolicyModule,
            CostCenterModule,
            ProfitCenterModule,
            ContractModule,
            // DriverModule,
            DocumentModule,
            // EmptyModule,
            // BiModule,
            LocationModule,
            EmploymentModule,
            UserModule,
            RoleModule,
            PermissionModule,
            BusinessUnitModule,
            CostCenterModule,
            ProfitCenterModule,
            TraceKmModule,
            // TyreSetModule,
            SetupModule,
            CostModule,
            // RenterModule,
            PoolModule,
            ServiceModule,
        ],
        copyright: '© MyFleetSolution',
        privacy: {
            label: 'Privacy policy',
            url: 'https://www.myfleetsolution.it'
        },
        auth0: {
            domain: process.env.REACT_APP_AUTH0_DOMAIN,
            clientId: process.env.REACT_APP_AUTH0_CLIENTID,
            authorizationParams: {
                redirect_uri: process.env.REACT_APP_AUTH0_AUTHORIZATIONPARAMS_REDIRECT_URI,
            }
        }
    }

    return (
        <App concreteconfig={config} />
    )
}

export default AppExecutive;
