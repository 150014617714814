import {  Default, Detail,  List, Create, ServiceAssociation } from "./views";
import { NavigationList } from "../NavigationList"


const Module = {
    name: "contracts",
    defaultRoutePath: "/cars/contract",
    menuItem: {
        scope: "fleet",
        title: "contracts",
        href: "/cars/contract",
        icon: "faFileSignature",
        hidden: true,
    },

    internalMenuItems: {
        activities: NavigationList["fleet"],
        wizards: []
    },
    routes: [
        {
            path: "/cars/contract",
            protected: true,
            children: [
                {
                    index: true,
                    path: "",
                    Component: Default,
                },
                {
                    path: "list",
                    Component: List,
                },
            ],
        },
    ],
    drawerRoutes: [
        {
            path: "list",
            Component: List,
        },
        {
            path: "detail",
            Component: Detail,
        },
        {
            path: "create",
            Component: Create,
        },
        {
            path: "serviceAssociation",
            Component: ServiceAssociation,
        },
        
    ],
   
}

export default Module;
