import { Create,  Detail,   List } from "./views";
import { NavigationList } from "../NavigationList"

const Module = {
    name: "imports",
    defaultRoutePath: "/imports",
    // menuItem: {
    //     // scope: "fleet",
    //     // title: "vehicles",
    //     // href: "/imports",
    //     // icon: "faCar"
    // },

    // internalMenuItems: {
    //     // activities: NavigationList["fleet"],
    //     // wizards: []
    // },
    routes: [
        // {
        //     path: "/imports",
        //     protected: true,
        //     children: [
        //         {
        //             index: true,
        //             path: "",
        //             Component: Default,
        //         },
        //         {
        //             path: "list",
        //             Component: List,
        //         },
        //     ],
        // },
    ],
    drawerRoutes: [
        {
            path: "list",
            Component: List,
        },
        {
            path: "detail",
            Component: Detail,
        },
        {
            path: "create",
            Component: Create,
        },
    ],
   
}

export default Module;
