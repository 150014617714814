import React from "react"
import { Box, FormControl, FormHelperText, FormLabel } from "@mui/joy";
import { Controller } from "react-hook-form";
import { useDrawer } from "../Drawer";
import { Button } from '../Button';
import Relationship from "../RelationshipElements/Relationship";

import { useTranslation } from 'react-i18next'

const InputRelationship = ({ detailStack = {}, modelName, item, relation, editing, required, name, control, label, view, placeholder, type = "relationship", hint, icon, haserror, disabled, readonly, className = "" }) => {
    //console.debug('InputRelationship args',{ detailStack, modelName, item, relation, editing, required, name, control, label, view, placeholder, type, hint, icon, haserror, disabled, readonly, className })
    const { t } = useTranslation();

    if (!editing) readonly = true;
    const isToMany = () => {
        return true;
        return relation.relationType === 'hasMany';
    }

    const isEnabled = (getValue) => {
        return (editing && (getValue().length === 0 || isToMany()))
    }

    const { activate } = useDrawer();

    return (
        <Controller
            name={name}
            control={control}
            render={({
                field: { onChange, value, name },
                fieldState: { error, invalid },
                formState,
            }) => {

                const getValue = () => {
                    if (!value) return [];
                    if (typeof value === 'undefined') return [];
                    if (Array.isArray(value)) return value;
                    return [value];
                }

                let generalClass = "form-element-control";
                generalClass += (editing) ? " editing" : " reading";
                generalClass += (invalid) ? " error" : "";
                generalClass += (required) ? " required" : "";
                generalClass += (disabled) ? " disabled" : "";
                generalClass += (readonly) ? " readonly" : "";

                const callbackAdd = (data) => {
                    // console.log("relationship callbackAdd", data);
                    if (typeof data === 'undefined') return;
                    if (isToMany()) {
                        onChange([...value, ...(Array.isArray(data) ? data : [data])])
                    } else {
                        onChange(Array.isArray(data) ? data.shift() : data);
                    }
                }

                const callbackManage = (data) => {
                    // console.log("relationship callbackManage", data);
                    if (typeof data === 'undefined') return;

                    if (isToMany()) {
                        onChange(Array.isArray(data) ? data : [data])
                    } else {
                        onChange(Array.isArray(data) ? data.shift() : data);
                    }
                }

                const callbackUpdate = (data) => {
                    // console.log("relationship callbackUpdate", data);
                    if (typeof data === 'undefined') return;

                    if (isToMany()) {
                        onChange([...value.filter((el) => el._id !== data._id), data]);
                    } else {
                        onChange(Array.isArray(data) ? data.shift() : data);
                    }
                }

                const handleItemRemove = (toBeRemoved) => {
                    // console.log("relationship handleItemRemove", toBeRemoved);
                    if (typeof toBeRemoved === 'undefined') return;

                    if (relation.relationDissociator) {
                        activate(relation.relationDissociator, {
                            defaultValue: toBeRemoved,
                            defaultSelectedValues: toBeRemoved._id,
                            enableSelection: true,
                            disableMultipleRowSelection: !isToMany(),
                            parent:{ ...item },
                            filters:{ ...relation.filter }
                        }, () => {})
                    } else {
                        if (!Array.isArray(value)) {
                            onChange(null)
                        } else {
                            onChange(value?.filter((el) => el._id !== toBeRemoved._id))
                        }
                    }
                }

                const handleItemDetailOpen = (v) => {

                    // console.log("relationship handleItemDetailOpen", v);
                    activate(relation.drawerRoute + '/detail', {
                        id:v._id,
                        defaultValue: v,
                        defaultSelectedValues: [],
                        enableSelection: true,
                        filters: v,
                        detailStack: {
                            inverseRelation: { [relation.inverseRelation]: item },
                            inverseRelationType: relation.inverseRelationType,
                            stack: [...(detailStack.stack ?? []), { [modelName]: item }]
                        }
                    }, callbackUpdate);
                }

                if (!relation.hasOwnProperty('limit')) relation.limit = 1000;

                return (
                    <div className={("form-element relationship " + className).trim()}>
                        <FormControl error={invalid} className={(error) ? generalClass + " form-element-error" : generalClass}>
                            <Box className="form-element-relationship-value">
                                <FormLabel className="form-element-label" htmlFor={name}>{t(label)}</FormLabel>
                            </Box>
                            <Box className="form-element-relationship-options" >
                                <Relationship
                                    view={view}
                                    value={getValue()}
                                    filters={item}
                                    relation={relation}
                                    callback={callbackManage}
                                    editing={editing}
                                    visiblelimit={relation.visiblelimit}
                                    handleItemDetail={handleItemDetailOpen}
                                    handleItemRemove={relation.relationDissociator != 'hidden' ? handleItemRemove : null}
                                />
                                { ((!Array.isArray(value) || relation.limit > value.length) && relation.relationAssociator != 'hidden') && (
                                <Box className="relationship-actions">
                                    {/* <Button
                                        size="medium"
                                        onClick={() => activate(relation.drawerRoute + '/create', {
                                            defaultValue: value,
                                            defaultSelectedValues: value,
                                            enableSelection: true,
                                            disableMultipleRowSelection: !isToMany(),
                                            filters: { ...item },
                                            detailStack: {
                                                inverseRelation: { [relation.inverseRelation]: item },
                                                inverseRelationType: relation.inverseRelationType,
                                                stack: [...(detailStack.stack ?? []), { [modelName]: item }]
                                            }
                                        }, callbackAdd)}
                                        //label={t("add")}
                                        icon={"faPlus"}
                                        variant="outlined"
                                        className="relationship-activator"
                                        disabled={!isEnabled(getValue)}
                                    /> */}

                                    <Button
                                        size="medium"
                                        onClick={() => activate(relation.relationAssociator ?? relation.drawerRoute + '/list', {
                                            defaultValue: value,
                                            defaultSelectedValues: value,
                                            enableSelection: true,
                                            disableMultipleRowSelection: !isToMany(),
                                            parent:{ ...item },
                                            filters:{ ...relation.filter },
                                            detailStack: {
                                                inverseRelation: { [relation.inverseRelation]: item },
                                                inverseRelationType: relation.inverseRelationType,
                                                stack: [...(detailStack.stack ?? []), { [modelName]: item }]
                                            }
                                        }, callbackManage)}
                                        label={t("select")}
                                        icon={"faListCheck"}
                                        variant="outlined"
                                        className="relationship-activator" 
                                        disabled={!isEnabled(getValue)}
                                    />
                                </Box>
                                )}
                            </Box>
                            <FormHelperText className="form-element-hint">
                                {(haserror || error) ? (
                                    <>
                                        {error ? error.message : t('error message')}
                                    </>
                                ) : (
                                    <>
                                        {hint}
                                    </>
                                )}
                            </FormHelperText>
                        </FormControl>
                    </div>
                )
            }}
        />
    );
}

export default InputRelationship
