import React from 'react';
import { useState, useEffect } from 'react';
import { Button, DrawerButton, List as ListComponent, useDrawer } from '../../../../mtska-frontend-app-component';
import { useGridApiRef } from '@mui/x-data-grid'
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { useTranslation } from 'react-i18next';

const dataProviderName = 'imports/job';
const drawerRouteBase = 'imports';

const List = (params) => {
    const { t } = useTranslation();
    const apiRef = useGridApiRef();
    const getselected = () => {
        return [...apiRef.current.getSelectedRows()].map(([name, value]) => ({ name, value }))
    }

    const [cap, setCap] = useState();

    const { item } = useDataProvider('configs/config');
    useEffect(() => {
        if (typeof item?.data?.capabilities === 'undefined') return;
        setCap(item.data.capabilities)
    }, [item])

    const { activate } = useDrawer();

    const { loadAll, items, total, loading } = useDataProvider(dataProviderName);
    const [listFilterPageSort, setListFilterPageSort] = useState();
    const handleFilterPageSortChange = (model) => {
        setListFilterPageSort(model);
    }
    useEffect(() => {
        const filterModel = {
            ...listFilterPageSort?.filterModel ?? {},
            ...params.filters
        };

        if ((typeof listFilterPageSort != 'undefined')) {
            loadAll && loadAll(filterModel, listFilterPageSort?.sortModel, listFilterPageSort?.paginationModel?.page + 1, listFilterPageSort?.paginationModel?.pageSize);
        }
    }, [listFilterPageSort, params.filters]);

    const handleRowDoubleclick = (row) => {
        activate(drawerRouteBase + '/detail', { id: row.id }, loadAll);
    }

    return (
        <>
            {cap && (
                <ListComponent
                    {...params}
                    onRowDoubleClick={handleRowDoubleclick}
                    onFilterPageSortChange={handleFilterPageSortChange}
                    items={items}
                    total={total}
                    loading={loading}
                    apiRef={apiRef}
                    enableSelection
                    rightButtons={[
                        <Button disabled={cap && !cap[dataProviderName+'.archive']}
                            icon={"faTrash"}
                            onClick={() => {
                                activate('imports/archive', { filters: getselected() });
                            }} />,
                        <DrawerButton
                            drawerRoute={'imports/create'}
                            params={{
                                detailStack: params.detailStack ?? {},
                                dataProviderName: params.currentDataProviderName,
                                moduleName: params.moduleName,
                            }}
                            callback={handleFilterPageSortChange}
                            label={t("New Import")} />,

                    ]}
                    dataProviderName={dataProviderName}
                    title={t("Import")} />
            )}
        </>
    );
}

export default List;


