import React from 'react';
import { Box, List, ListItem } from '@mui/joy';

const CellPerson = ({ value }) => {

    const getFirstCharacter = (input) => {
        if (typeof input === 'string' || input instanceof String) {
            return input.charAt(0)
        }
        return 'N';
    }

    return (
        (value && (
                <Box className={"list-element-cell cell-person"}>
                    <span className="name" title={value?.firstname + " " + value?.lastname}>{getFirstCharacter(value?.firstname) + ". " + value?.lastname}</span>
                </Box>
            ) 
        )
    );
}

export default CellPerson