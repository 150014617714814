import React from "react"
import { useState, useEffect } from "react"
import { useDropzone } from "react-dropzone";
import { FormControl, FormHelperText, FormLabel } from "@mui/joy";
import { Icon } from "../../assets";
import { Text } from "../Typography";
import { useTranslation } from 'react-i18next';

const Preview = ({ previews }) => {

    return <>
        {Array.isArray(previews) && (
            <ul className="filesPreview">
                {previews.map(file => (
                    <li key={file.path}>
                        <div className="filePreview">
                            <Icon icon="faFile"></Icon>
                            <Text level="title-sm">{file.path}</Text>
                            {/* <Text level="body-sm">{file.size} bytes</Text> */}
                        </div>
                    </li>
                ))}
            </ul>
        )}
    </>

}

const InputDropzoneElement = ({ id, name, defaultValue = [], label, hint, error, invalid, editing, required, multiple, disabled, readonly, className, onDrop }) => {

    const { t } = useTranslation();

    const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({
        onDrop: onDrop,
        multiple,
        disabled
    });

    const [previews, setPreviews] = useState([])
    useEffect(() => {
        setPreviews(defaultValue);
    }, [defaultValue])

    useEffect(() => {
        setPreviews(acceptedFiles);
    }, [acceptedFiles])

    if (!editing) readonly = true;

    let outerClass = "form-element-control";
    outerClass += (editing) ? " editing" : " reading";
    outerClass += (error) ? " error" : "";
    outerClass += (required) ? " required" : "";
    outerClass += (disabled) ? " disabled" : "";
    outerClass += (readonly) ? " readonly" : "";

    return (
        <div className={("form-element dropbox " + className).trim()}>
            <FormControl error={error} className={(invalid) ? outerClass + " form-element-error" : outerClass}>
                <FormLabel className="form-element-label" htmlFor={name}>{t(label)}</FormLabel>

                <div {...getRootProps()} className="form-element-dropbox-area">
                    <input {...getInputProps()} />
                    {
                        (!Array.isArray(previews) || previews.length === 0) && (<span className="info">{t('Drag and drop some files here, or click to select files')}</span>)
                    }

                    <Preview previews={previews} />
                </div>

                <FormHelperText className="form-element-hint">
                    {(invalid) ? (
                        <>
                            {error ? error.message : 'Messaggio di errore'}
                        </>
                    ) : (
                        <>
                            {hint}
                        </>
                    )}
                </FormHelperText>

            </FormControl>
        </div>
    )
}

export default InputDropzoneElement;