import { Default, Detail, List } from "./views";
import { NavigationList } from "../NavigationList"



const Module = {
    name: "users",
    defaultRoutePath: "/people/user",
    menuItem: {
        scope: "people",
        title: "users",
        href: "/people/user",
        icon: "faUser",
        hidden: true
    },

    internalMenuItems: {
        activities: NavigationList["people"],
        wizards: []
    },
    routes: [
        {
            path: "/people/user",
            protected: true,
            children: [
                {
                    index: true,
                    path: "",
                    Component: Default,
                },
                {
                    path: "list",
                    Component: List,
                },
            ],
        },
    ],
    drawerRoutes: [
        {
            path: "list",
            Component: List,
        },
        {
            path: "detail",
            Component: Detail,
        },
    ],
   
}

export default Module;
