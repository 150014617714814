import { Default, Detail, List,  Create } from "./views";
import { NavigationList } from "../NavigationList"



const Module = {
    name: "services",
    defaultRoutePath: "/cars/service",
    menuItem: {
        scope: "fleet",
        title: "services",
        href: "/cars/service",
        icon: "faCar",
        hidden: true
    },
    internalMenuItems: {
        activities: NavigationList["fleet"],
        wizards: []
    },
    routes: [
        {
            path: "/cars/service",
            protected: true,
            children: [
                {
                    index: true,
                    path: "",
                    Component: Default,
                },
                {
                    path: "list",
                    Component: List,
                },
            ],
        },
    ],
    drawerRoutes: [
        {
            path: "list",
            Component: List,
        },
        {
            path: "detail",
            Component: Detail,
        },
        
        {
            path: "create",
            Component: Create,
        },
    ],

}

export default Module;
