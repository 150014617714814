import React from "react"

import { Button as JoyButton} from "@mui/joy";
import { Icon } from '../../assets';

const Button = ({label, icon, family, disabled, loading, variant, size = "regular", onClick, className, tooltip, children}) => {

    className = (((typeof className != typeof undefined) ? className : '') + " input-button " + size).trim();
    if (!label && !children) className += " iconic";
    return (
        <JoyButton 
            variant={variant}
            onClick={onClick}
            disabled={disabled}
            loading={loading}
            className={className}
            startDecorator={(icon) ? (
                <Icon icon={icon} family={family}/>
            ) : (
                <></>
            )}
        >
            {(label) ? label : children}
            {(tooltip) && <span className="tooltip">{tooltip}</span>}
        </JoyButton>
    );
};


export default Button;
