import React from "react"
import InputTextControl from "./InputTextControl";
import InputHiddenControl from "./InputHiddenControl";
import InputTextarea from "./InputTextarea";
import InputDateControl from "./InputDateControl";
import InputEmailControl from "./InputEmailControl";
import InputFileControl from "./InputFileControl";
import InputDropzoneControl from "./InputDropzoneControl";
import InputNumberControl from "./InputNumberControl";
import InputSelect from "./InputSelect";
import InputRadio from "./InputRadio";
import InputCheckbox from "./InputCheckbox";
import InputSwitch from "./InputSwitch";
import InputList from "./InputList"
import InputRelationship from "./InputRelationship";
import InputRelationshipSingle from "./InputRelationshipSingle";
import SectionTitle from "./SectionTitle";
import ViewEmploymentDetail from "./ViewEmploymentDetail";
import ViewCostList from "./ViewCostList";
import ViewDocumentList from "./ViewDocumentList";
import ViewContractDetail from "./ViewContractDetail";
import WidgetMakeModelSetup from "./WidgetMakeModelSetup";
import WidgetPoolOfDriver from './WidgetPoolOfDriver';
import WidgetAuthorizedsOfDriver from './WidgetAuthorizedsOfDriver';
import WidgetServicesOfDriver from './WidgetServicesOfDriver';
import WidgetCostCar from './WidgetCostCar';
import WidgetCostPerson from './WidgetCostPerson';
import WidgetCostContract from './WidgetCostContract';
import WidgetCostService from './WidgetCostService';
import WidgetCostSupplier from './WidgetCostSupplier';
import InputListCar from "./InputListCar";
import InputListPool from "./InputListPool";
import InputListService from "./InputListService";
import InputListPerson from "./InputListPerson";
import InputListContract from "./InputListContract";
import InputListEmployment from "./InputListEmployment";

const Input = (props) => {
    if (props.type === 'text') return <InputTextControl {...props} />
    if (props.type === 'file') return <InputFileControl {...props} />
    if (props.type === 'dropzone') return <InputDropzoneControl {...props} />
    if (props.type === 'hidden') return <InputHiddenControl {...props} />
    if (props.type === 'textarea') return <InputTextarea {...props} />
    if (props.type === 'number') return <InputNumberControl {...props} />
    if (props.type === 'date') return <InputDateControl {...props} />
    if (props.type === 'email') return <InputEmailControl {...props} />
    if (props.type === 'select') return <InputSelect {...props} />
    if (props.type === 'radio') return <InputRadio {...props} />
    if (props.type === 'checkbox') return <InputCheckbox {...props} />
    if (props.type === 'switch') return <InputSwitch {...props} />
    if (props.type === 'relationship') return <InputRelationship {...props} />
    if (props.type === 'relationshipSingle') return <InputRelationshipSingle {...props} />
    if (props.type === 'sectiontitle') return <SectionTitle {...props} />
    if (props.type === 'list') return <InputList {...props} />

    if (props.type === 'makeModelSetup') return <WidgetMakeModelSetup {...props} />

    if (props.type === 'carlist') return <InputListCar {...props} />
    if (props.type === 'poollist') return <InputListPool {...props} />
    if (props.type === 'personlist') return <InputListPerson {...props} />
    if (props.type === 'employmentlist') return <InputListEmployment {...props} />
    if (props.type === 'servicelist') return <InputListService {...props} />
    if (props.type === 'contractlist') return <InputListContract {...props} />

    if (props.type === 'costlist') return <ViewCostList {...props} />
    if (props.type === 'documentlist') return <ViewDocumentList {...props} />
    if (props.type === 'employmentdetail') return <ViewEmploymentDetail {...props} />
    if (props.type === 'contractdetail') return <ViewContractDetail {...props} />

    switch (props.type) {
        case 'vehicleWidget':
            return <WidgetPoolOfDriver {...props} />
        case 'authorizedsWidget':
            return <WidgetAuthorizedsOfDriver {...props} />
        case 'costCarWidget':
            return <WidgetCostCar {...props} />
        case 'costPersonWidget':
            return <WidgetCostPerson {...props} />
        case 'costContractWidget':
            return <WidgetCostContract {...props} />
        case 'costServiceWidget':
            return <WidgetCostService {...props} />
        case 'costSupplierWidget':
            return <WidgetCostSupplier {...props} />
        case 'serviceWidget':
            return <WidgetServicesOfDriver {...props} />
        default:
            return null;

    }
    return <InputTextControl {...props} />

};

export default Input
