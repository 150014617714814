import React from 'react';
import { ListItem, ListItemContent, Box } from "@mui/joy";
import { Button } from '../Button';

import { useTranslation } from 'react-i18next'
import { ExpirationTermometer, Termometer, Tag, LegalEntityAvatar } from '../Snippet';
import { useFormatter } from '../../utils';

const RelationshipService = ({ k, v, editing, handleItemDetail, handleItemRemove }) => {
    const { t, i18n } = useTranslation();
    const { formatCurrency } = useFormatter();
    const tagcolors = {
        active: "success",
        inactive: "danger"
    }
    
    const consumptionPercentage = (v?.monthlyLimit) ? v?.monthlyCosts / parseFloat(v?.monthlyLimit) : 0;

    return (
        <ListItem key={k} variant="outlined" className={"relationship-element"}>
            <ListItemContent className={"relationship-element-content"}>
                <Box className={"coupled"}>
                    <span className={"label"} >{t(v?.name)}</span>
                    <span className={"value"}>{t(v?.identifier)}</span>
                </Box>
                <LegalEntityAvatar name={v?.issuer?.name} url={v?.issuer?.logo?.url} withname={true} detail={t(v?.type) + " | " + t(v?.assignedModel)}/>
                <Box className={"status"}>
                    <Tag 
                    color={(v?.status && tagcolors[v.status.toLowerCase()])} 
                    size="small" 
                    label={t(v?.status && v?.status.toLowerCase())} />
                </Box>
                <Box className={"analysis norightborder"}>
                    <Termometer startvalue={formatCurrency(0)} endvalue={(v?.monthlyLimit) ? formatCurrency(v?.monthlyLimit) : t('Limit missing')} percentage={consumptionPercentage} color={(v?.monthlyLimit && v?.monthlyCosts) ? ((consumptionPercentage < 0.7) ? "secondary" : "warning") : "danger"} hint={v?.monthlyCosts ? formatCurrency(v?.monthlyCosts) : t('Missing monthly costs')} hintposition={"top"} />
                    {/* <ExpirationTermometer installationDate={v?.installationDate} expirationDate={v?.expirationDate} orderDate={v?.orderDate} expectedInstallationDate={v?.expectedInstallationDate} /> */}
                </Box>
            </ListItemContent>

            {editing && (<Button onClick={() => handleItemDetail(v)} icon="faPen" variant="plain" />)}
            {(editing && handleItemRemove) && (<Button onClick={() => handleItemRemove(v)} icon="faClose" variant="plain" />)}
        </ListItem>
    )
}

export default RelationshipService



