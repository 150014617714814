import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';

const dataProviderName = 'cars/car';
const drawerRouteBase = 'cars';

const PoolDissociation = (params) => {
    const { t } = useTranslation();
    const { close } = useDrawer();
    const [cap, setCap] = useState();

    // DATA PROVIDER
    const { item, save, loadOne, loadAll, destroy, errorMessage, errors, saving, success } = useDataProvider(dataProviderName, params.multipart);
    const { item: config } = useDataProvider('configs/config');

    useEffect(() => {
        if (typeof config?.data?.capabilities === 'undefined') return;
        setCap(config.data.capabilities)
    }, [config]);

    let dsvs = params.defaultSelectedValues;
    if (typeof dsvs == 'string') {
        dsvs = dsvs.split(',').map((dsv) => { return {'_id': dsv}});
    } else if (typeof dsvs == 'undefined') {
        dsvs = [];
    } else{
        dsvs = [dsvs];
    }

    const [detailConfig, setDetailConfig] = useState();
    const [modelName, setModelName] = useState();
    useEffect(() => {
        if (typeof config?.data?.modules === 'undefined') return;
        if (typeof config.data.modules[dataProviderName] === 'undefined') return;
        //setDetailConfig(config.data.modules[dataProviderName].detailConfig);
        setDetailConfig({
            "tabs": [
                {
                    "name": "dissociation",
                    "fieldsets": [
                        {
                            "name": "dissociation",
                            "fields": {
                                "pool_assigned": {
                                    "type": "sectiontitle",
                                    "className": "col-12"
                                },
                                "dissociated": {
                                    "type": "poollist",
                                    "defaultSelectedValues":[], 
                                    "enableSelection":true, 
                                    "disableMultipleRowSelection":true,
                                    "dataProviderName":"cars/pool",
                                    "filters": {
                                        "items": [
                                            {
                                                "field": "_id",
                                                "operator": "isAnyOf",
                                                "value": [item?.data?.pool?._id]
                                            }
                                        ]
                                    },
                                    "className": "col-12",
                                    "required": "true"
                                },
                            }
                        }
                    ]
                }
            ],
            "validation": []
        });

        setModelName(config.data.modules[dataProviderName].modelName);
    }, [config, dataProviderName]);

    const { save: saveJob } = useDataProvider('queues/job');
    const handleSave = async (data) => {
        saveJob && await saveJob({ action: 'poolDissociation', model: dataProviderName, data: data });
        loadOne && await loadOne(item?.data?._id);
        close();
    }

    return <>
        <DetailComponent
            values={{ "id": item?.data?._id, "dissociated": dsvs?.map((dsv) => dsv._id) }}
            modelName={modelName}
            detailConfig={detailConfig}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t('Pool dissociation')}
            header={
                <InfoHeader
                    view="wizard"
                    title={t('Dissociate Pool')}
                    subtitle={t('Remove {{carIdentifier}} from a Pool', { carIdentifier: (item.data.plate ? item.data.plate : item.data.setup?.make+" "+item.data.setup?.model) })} 
                />
            }
            {...params}
        />
    </>
}

export default PoolDissociation;


