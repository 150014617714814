import React from "react";
import { List, ListItem, Box } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { PersonAvatar, LegalEntityAvatar, Tag } from "../Snippet";
import { Anchor } from "../Typography";
import { Icon } from "../../assets";
import { useDrawer } from "../Drawer";
import { useFormatter } from "../../utils";

const InfoHeaderPerson = ({ view, item, control }) => {
    const { formatDate } = useFormatter();
    const { t } = useTranslation();
    const { activate } = useDrawer();

    const handleAssociateEmployment = () => {
        activate('persons/employmentAssociation', {
            id:item?.id,
            defaultValue: item,
            filters: item,
        });
    };

    const isDriver = () => {
        return (Array.isArray(item?.employments) &&
            item?.employments.length > 0 &&
            typeof item?.employment?.pools === 'object' &&
            item?.employment?.pools !== null
        )
    }

    const isDriverPool = () => {
        return (Array.isArray(item?.employments) &&
            item?.employments.length > 0 &&
            typeof item?.employment?.pools === 'object' &&
            item?.employment?.pools !== null &&
            Array.isArray(item?.employment?.pools.cars) &&
            item?.employment?.pools.cars.length > 1
        )
    }

    return (
        <Box className={"info-header info-header-" + view}>
            <PersonAvatar firstname={item?.firstname} lastname={item?.lastname} size="big" url={item?.picture?.url} />
            <Box className={"info-wrapper"}>
                {item?.name !== ' ' ? (
                    <>
                        <Box className="info-legal-entity">
                            {
                                (
                                    item?.authorizedBy == null
                                ) ? (
                                    <LegalEntityAvatar name={item?.legalEntity?.name} url={item?.legalEntity?.logo?.url} size="small" withname={true} />
                                ) : (
                                    <LegalEntityAvatar name={item?.authorizedBy?.legalEntity?.name} url={item?.authorizedBy?.legalEntity?.logo?.url} size="small" withname={true} />
                                )
                            }
                        </Box>
                        <Box className="info-name">
                            {item?.firstname} {item?.lastname}
                        </Box>
                        <Box className="info-appandcompanyroles">
                            {
                                (
                                    isDriver()
                                ) && (
                                    <Tag color={"secondary"} label={t("Driver")} />
                                )
                            }
                            {
                                (
                                    isDriverPool()
                                ) && (
                                    <Tag color={"secondary"} label={t("Driver Pool")} />
                                )
                            }
                            {
                                (
                                    item?.authorizedBy != null
                                ) && (
                                    <>
                                        <Tag color={"secondary"} label={t("Authorized driver")} />
                                        <Tag color={"success"} label={item?.authorizedBy?.person?.firstname + ' ' + item?.authorizedBy?.person?.lastname + ' (' + item?.authorizedBy?.role + "@" + item?.authorizedBy?.legalEntity?.name + ')'} />
                                    </>
                                )
                            }
                            {
                                (
                                    item?.authorizedBy == null && item?.employment == null
                                ) && (
                                    <Tag color={"danger"} title={t("Missing Employment")} onClick={handleAssociateEmployment}><Icon icon="faWarning" />{t('Missing Employment')}</Tag>
                                )
                            }
                            {
                                (
                                    item?.authorizedBy == null && item?.employment
                                ) && (
                                    <Tag color={"success"} label={item?.employment?.role + "@" + item?.employment?.legalEntity?.name} />
                                )
                            }
                        </Box>
                        <List className="info-contacts">
                            {item?.email && (
                                <ListItem className="info-contacts-email">
                                    <Anchor href={"mailto:" + item?.email} icon="faEnvelope" iconfamily='fal'>{item?.email}</Anchor>
                                </ListItem>
                            )}
                            {item?.mobile && (
                                <ListItem className="info-contacts-telephone">
                                    <Anchor href={"tel:" + item?.mobile} icon="faPhone" iconfamily='fal' target="_blank">{item?.mobile}</Anchor>
                                </ListItem>
                            )}
                            {item?.hasWhatsapp === 'yes' && (
                                <ListItem className="info-contacts-whatsapp">
                                    <Anchor href={"https://wa.me/" + item?.mobile.replace(/[\+\ \-\_]/g, '')} icon="faWhatsapp" iconfamily='fab' target="_blank"></Anchor>
                                </ListItem>
                            )}
                            {item?.hasTelegram === 'yes' && (
                                <ListItem className="info-contacts-telegram">
                                    <Anchor href={"https://t.me/" + item?.mobile.replace(/[\+\ \-\_]/g, '')} icon="faTelegram" iconfamily='fab' target="_blank"></Anchor>
                                </ListItem>
                            )}
                        </List>
                    </>
                ) : (
                    <>
                        <Box className="info-legal-entity">
                            <LegalEntityAvatar name='?' size="small" />
                        </Box>
                        <Box className="info-name">
                            {t('New Person')}
                        </Box>
                    </>
                )}
            </Box>
            <Box className={"info-activity-wrapper"}>
                {item?.name != ' ' && (
                    <>
                        {/* <Input
                    type="switch"
                    name={"active"}
                    control={control}
                    ischecked={item?.active}
                    editing={true}
                    required={false}
                    checkedlabel={t("active")}
                    uncheckedlabel={t("inactive")}
                /> */}
                        <Box className={"info-lastlogin"}>
                            <span className="key">
                                {t('Last login')}
                            </span>
                            <span className="value">
                                {formatDate(new Date())}
                            </span>
                            <span className="key">
                                {t('Number of logins')}
                            </span>
                            <span className="value">
                                {parseInt(Math.random() * 100)}
                            </span>
                        </Box>
                    </>
                )}
            </Box>
        </Box>
    )
}

export default InfoHeaderPerson;