import React from 'react';
import { Box } from '@mui/joy';
import { useTranslation } from 'react-i18next';

const CellSimple = ({ value, type = 'simple' }) => {
    const { t } = useTranslation();
    return (
        <Box className={"list-element-cell cell-" + type}>
            {type !== 'simple' && '(' + type + ')'}
            {value} 
        </Box>
    )
}

export default CellSimple