import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, List as ListComponent, useDrawer, Confirm, DrawerButton } from '../../../../mtska-frontend-app-component';
import { useGridApiRef } from '@mui/x-data-grid';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import ImportButton from '../../Import/components/ImportButton';

const dataProviderName = 'companies/location';
const drawerRouteBase = 'locations';
const moduleName = "Locations";

const List = (params) => {
    const { t } = useTranslation();
    const [cap, setCap] = useState();
    const { item } = useDataProvider('configs/config');

    useEffect(() => {
        if (typeof item?.data?.capabilities === 'undefined') return;
        setCap(item.data.capabilities);
    }, [item]);

    const { activate } = useDrawer();

    const apiRef = useGridApiRef();
    const getSelected = () => {
        return [...apiRef.current.getSelectedRows()].map(([name]) => name);
    };
    const [selected, setSelected] = useState([]);
    const handleRowSelection = (newSelection) => {
        setSelected(newSelection);
        params.rowSelectionExternalListener && params.rowSelectionExternalListener(newSelection);
    };

    const getFunctionalButtons = useMemo(() => {
        let rb = [];

        if (selected.length) {
            rb.push(
                <Button
                    restoreMe_disabled={cap && !cap[dataProviderName + '.archive']}
                    onClick={() => { handleDelete(); }}
                    label={t("Delete") + " (" + selected.length + ")"}
                    icon={"faTrash"}
                    variant="outlined"
                    size="small"
                />
            );
        }

        return rb;
    }, [selected, cap]);

    const { save: saveJob } = useDataProvider('queues/job');
    const [confirmDelete, setConfirmDelete] = useState();
    const handleDelete = () => {
        setConfirmDelete(
            <Confirm
                oklabel={t("Delete")}
                okcallable={async () => {
                    saveJob && await saveJob({ action: 'destroy', model: dataProviderName, data: getSelected() });
                    setConfirmDelete(null);
                    apiRef.current.setRowSelectionModel([]);
                    loadAll();
                }}
                kolabel={t("Cancel")}
                kocallable={() => { setConfirmDelete(null) }}
                title={t("Items elimination")}>
                {t("Are you sure to remove the selected elements?")}
            </Confirm>
        );
    }

    const { loadAll, items, total, loading } = useDataProvider(dataProviderName);
    const [listFilterPageSort, setListFilterPageSort] = useState();
    const handleFilterPageSortChange = (model) => {
        setListFilterPageSort(model);
    }
    useEffect(() => {
        const filterModel = {
            ...listFilterPageSort?.filterModel ?? {},
            ...params.filters
        };

        if ((typeof listFilterPageSort != 'undefined')) {
            loadAll && loadAll(filterModel, listFilterPageSort?.sortModel, listFilterPageSort?.paginationModel?.page + 1, listFilterPageSort?.paginationModel?.pageSize);
        }
    }, [listFilterPageSort, params.filters]);

    const handleRowDoubleclick = (row) => {
        activate(drawerRouteBase + '/detail', { id: row.id }, loadAll);
    }

    return (
        <>
            {cap && (
                <ListComponent
                    {...params}
                    onRowDoubleClick={handleRowDoubleclick}
                    onFilterPageSortChange={handleFilterPageSortChange}
                    items={items}
                    total={total}
                    loading={loading}
                    apiRef={apiRef}
                    enableSelection
                    rightButtons={[
                        ...getFunctionalButtons,
                        <DrawerButton
                            drawerRoute={drawerRouteBase + '/create'}
                            params={{ detailStack: params.detailStack ?? {} }}
                            label={t("New")} />,
                        <ImportButton
                            dataProviderName={dataProviderName}
                            callback={(data) => {
                                loadAll();
                            }}
                        />,
                    ]}
                    dataProviderName={dataProviderName}
                    rowSelectionExternalListener={handleRowSelection}
                    title={"Permissions"}
                />
            )}
            {confirmDelete}
        </>
    );
};

export default List;


