import React from "react";
import { Box } from "@mui/joy";

import { useTranslation } from 'react-i18next'
import { useFormatter } from "../../utils";

const Termometer = ({ percentage, color, hint, hintposition = "top", startvalue = null, endvalue = null }) => {
    const { i18n } = useTranslation();
    const { formatPercentage } = useFormatter();

    return (
        <Box className={"termometer termometer-" + color + " termometer-" + hintposition}>

            <Box className="starvalue">{startvalue}</Box>
            <Box className="endvalue">{endvalue}</Box>

            <Box className={"rail rail-" + color}>
                <Box className={"runner runner-" + color} sx={{ width: ((percentage > 1 ? '100%' : parseInt(percentage * 100) + '%')) }}></Box>

                <Box className="percentage" sx={{ marginLeft: ((percentage > 1 ? '100%' : parseInt(percentage * 100) + '%')) }}>
                    {formatPercentage(percentage)}
                </Box>
            </Box>
            <Box className={"hint hint-" + color}>{hint}</Box>
        </Box>
    )
}

export default Termometer;