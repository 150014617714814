import React from 'react';
import { ListItem, ListItemContent, Box } from "@mui/joy";
import { Button } from '../Button';

import { useTranslation } from 'react-i18next'
import { TraceKmTermometer } from '../Snippet';
import { useFormatter } from '../../utils';

const RelationshipTraceKm = ({ k, v, editing, handleItemDetail, handleItemRemove }) => {
    const { t, i18n } = useTranslation();
    const { formatDate, formatDistance } = useFormatter();

    let trace_date
    if (typeof v?.traced_at != typeof undefined) {
        trace_date = formatDate(new Date(v.traced_at));
    } else {
        trace_date = '-';
    }

    return (
        <ListItem key={k} variant="outlined" className={"relationship-element"}>
            <ListItemContent className={"relationship-element-content"}>
                <Box className="coupled">
                    <Box className="label">{t('KM')}</Box>
                    <Box className="value">
                        <span>{formatDistance(v?.value)}</span>
                    </Box>
                </Box>
                <Box className={"coupled"}>
                    <Box className="label">{t('Date')}</Box>
                    <Box className="value">
                        <span>{trace_date}</span>
                    </Box>
                </Box>
                <Box className={"analysis norightborder"}>
                    <TraceKmTermometer tracedate={v.traced_at} />
                </Box>
            </ListItemContent>
            {editing && (<Button onClick={() => handleItemDetail(v)} icon="faPen" variant="plain" />)}
            {/* {(editing && handleItemRemove) && (<Button onClick={() => handleItemRemove(v)} icon="faClose" variant="plain" />)} */}
        </ListItem>
    )
}

export default RelationshipTraceKm;



