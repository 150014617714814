export const useRelationshipInjector = () => {

    const injectToDetail = (destination, key , data) => {

        if (!destination || typeof destination == 'undefined') {
            console.log('injectToDetail failed: wrong destination', destination)
        } else if (!destination.hasOwnProperty(key)) {
            destination[key] = data;
        }
        
    }


    return {
        injectToDetail
    }
};
