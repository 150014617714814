export const useString = () => {

    const firstCharacter = (input) => {
        if (typeof input === 'string' || input instanceof String) {
            return input.charAt(0)
        }
        return '*';
    }
    
    const toColor = (str) => {
        if (typeof str == typeof undefined) str = 'undefined';
        
        let hash = 0;
        str.split('').forEach(char => {
            hash = char.charCodeAt(0) + ((hash << 5) - hash)
        })
        let colour = '#'
        for (let i = 0; i < 3; i++) {
            const value = (hash >> (i * 8)) & 0xff
            colour += value.toString(16).padStart(2, '0')
        }
        return colour
    }

    return {
        firstCharacter,
        toColor,
    }
};
