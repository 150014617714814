import React from 'react';
import { Icon } from '../../assets';
import { LegalEntityAvatar } from '../Snippet';
import { Box, List, ListItem } from '@mui/joy';
import { useTranslation } from 'react-i18next';

const CellContract = ({ type, value, limit = 1 }) => {

    const { t } = useTranslation();
    if (!Array.isArray(value) && value) {
        value = [value];
    } else if (value == null) {
        value = [];
    }

    const length = (value) ? Object.keys(value).length : 0;
    let more = null;

    if (length > limit) {
        more = <ListItem className={'cell-list-item-count cell-contract-list-item-count'}>
            <span>+{length - limit}</span>
        </ListItem>
    }

    return (
        <Box className={"list-element-cell cell-contract cell-contract-" + type}>
            {length > 0 ? (
                <List className="cell-contract-list">
                    {value.map((item, k) => {
                        if (k >= limit || item == null) return;
                        return (
                            <ListItem key={k} className={'cell-generics-list-item'}>
                                <LegalEntityAvatar name={item.renter?.name} detail={item.identifier} url={item.renter?.logo?.url} withname={true} />
                            </ListItem>
                        );
                    })}
                    {more}
                </List>
            ) : (
                <List className="cell-contract-list">
                    <ListItem key={0} className={'cell-generics-list-item'}>
                        <span className={"status status-warning"}>
                            <Icon icon="faWarning" />
                            {t('No contract')}
                        </span>
                    </ListItem>
                </List>
            )}
        </Box>
    )

}

export default CellContract;