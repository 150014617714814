import React from 'react';
import { Box } from '@mui/joy';
import { useTranslation } from 'react-i18next'
import { useFormatter } from '../../utils';

const CellCurrency = ({ value, type }) => {
    const { formatCurrency } = useFormatter();
    const { t, i18n } = useTranslation();
    
    return <Box className={"list-element-cell cell-" + type}>{ (value) ? formatCurrency(value) : '-' }</Box>
}

export default CellCurrency