import React from "react";
import { useFormatter } from '../../utils';

const WidgetCostElementTotal = ({ costbreakdown }) => {
    const { formatCurrency } = useFormatter();

    if (typeof costbreakdown == 'undefined' || !Array.isArray(costbreakdown)) return;

    const totals = {
        work_cost: 0,
        personal_cost: 0
    };

    // Itera attraverso l'array e somma i totali per costType
    costbreakdown.forEach(item => {
        if (item.costType === "work_cost" || item.costType === "personal_cost") {
            totals[item.costType] += item.total;
        }
    });

    return <tr>
        <td></td>
        <td>{formatCurrency(totals.work_cost / 100)}</td>
        <td>{formatCurrency(totals.personal_cost / 100)}</td>
    </tr>
}

export default WidgetCostElementTotal;