import React from "react"

import { CarsOfPool, PoolsOfEmployment, EmploymentsOfPerson, CarsOfPerson } from "../../../mtska-frontend-modules";

const WidgetPoolOfDriver = ({ editing, item, readonly }) => {
    if (!editing) readonly = true;

    return (
        <>
            {
                (item?.authorizedBy && Array.isArray(item?.authorizedBy.pools) && item?.authorizedBy.pools.length > 0) && (
                    // se è un utente autorizzato

                    item?.authorizedBy.pools.map((pool, kk) => {
                        return <><CarsOfPool
                            filters={pool}
                            key={kk}
                        />
                        </>
                    })

                )
            }

            {
                item?.authorizedBy == null &&
                    (
                       <><CarsOfPerson
                            item={item}
                        />
                        </>
                    ) 
            }

            {

                (item?.authorizedBy == null && item.id && (!Array.isArray(item.employments) || item.employments.length == 0)) && (
                    // se non ha un employment
                    <><EmploymentsOfPerson
                        filters={item}
                    /></>
                )
            }
        </>
    );


};


export default WidgetPoolOfDriver;
