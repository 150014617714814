import React from "react"
import RelationshipSimple from "./RelationshipSimple";
import RelationshipCar from "./RelationshipCar";
import RelationshipSetup from "./RelationshipSetup";
import RelationshipFileGeneric from "./RelationshipFileGeneric";
import RelationshipFileDriverLicence from './RelationshipFileDriverLicence';
import RelationshipPerson from './RelationshipPerson';
import RelationshipEmployment from './RelationshipEmployment';
import RelationshipEmployments from './RelationshipEmployments';
import RelationshipEmploymentsWithPerson from './RelationshipEmploymentsWithPerson';
import RelationshipDriverDelegate from "./RelationshipDriverDelegate";
import RelationshipService from "./RelationshipService";
import RelationshipLegalEntity from "./RelationshipLegalEntity";
import RelationshipTraceKm from "./RelationshipTraceKm";
import RelationshipTyreSet from "./RelationshipTyreSet";
import RelationshipContract from "./RelationshipContract";
import RelationshipPool from "./RelationshipPool";

import { List, ListItem, ListItemContent } from "@mui/joy";
import { useTranslation } from 'react-i18next'

const Relationship = ({ view = 'simple', value, editing, visiblelimit, handleItemDetail, handleItemRemove, filters, relation, callback }) => {
    const { t, i18n } = useTranslation();

    const getItem = (v, k) => {
        switch (view) {
            case 'car':
                return <><RelationshipCar
                    k={k}
                    v={v}
                    editing={editing}
                    handleItemDetail={handleItemDetail}
                    handleItemRemove={handleItemRemove}
                /></>
            case 'setup':
                return <RelationshipSetup
                    k={k}
                    v={v}
                    editing={editing}
                    handleItemDetail={handleItemDetail}
                    handleItemRemove={handleItemRemove}
                />
            case 'contract':
                return <RelationshipContract
                    k={k}
                    v={v}
                    editing={editing}
                    handleItemDetail={handleItemDetail}
                    handleItemRemove={handleItemRemove}
                />
            case 'pool':
                return <><RelationshipPool
                    k={k}
                    v={v}
                    editing={editing}
                    handleItemDetail={handleItemDetail}
                    handleItemRemove={handleItemRemove}
                /></>
            case 'legalEntity':
                return <RelationshipLegalEntity
                    k={k}
                    v={v}
                    editing={editing}
                    handleItemDetail={handleItemDetail}
                    handleItemRemove={handleItemRemove}
                />
            case 'fileGeneric':
            case 'document':
            case 'picture':
                return <RelationshipFileGeneric
                    k={k}
                    v={v}
                    editing={editing}
                    handleItemDetail={handleItemDetail}
                    handleItemRemove={handleItemRemove}
                />
            case 'fileDriverLicence':
                return <RelationshipFileDriverLicence
                    k={k}
                    v={v}
                    editing={editing}
                    handleItemDetail={handleItemDetail}
                    handleItemRemove={handleItemRemove}
                />
            case 'driverdelegate':
                return <RelationshipDriverDelegate
                    k={k}
                    v={v}
                    editing={editing}
                    handleItemDetail={handleItemDetail}
                    handleItemRemove={handleItemRemove}
                />
            case 'service':
                return <RelationshipService
                    k={k}
                    v={v}
                    editing={editing}
                    handleItemDetail={handleItemDetail}
                    handleItemRemove={handleItemRemove}
                />
            case 'person':
                return <RelationshipPerson
                    callback={callback}
                    filters={value}
                    relation={relation}
                    k={k}
                    v={v}
                    editing={editing}
                    handleItemDetail={handleItemDetail}
                    handleItemRemove={handleItemRemove}
                />
            case 'employment':
                return <RelationshipEmployment
                    callback={callback}
                    filters={value}
                    relation={relation}
                    k={k}
                    v={v}
                    editing={editing}
                    handleItemDetail={handleItemDetail}
                    handleItemRemove={handleItemRemove}
                />
            case 'employments':
                return <RelationshipEmployments
                    callback={callback}
                    filters={value}
                    relation={relation}
                    k={k}
                    v={v}
                    editing={editing}
                    handleItemDetail={handleItemDetail}
                    handleItemRemove={handleItemRemove}
                />
            case 'employmentsWithPerson':
                return <RelationshipEmploymentsWithPerson
                    callback={callback}
                    filters={value}
                    relation={relation}
                    k={k}
                    v={v}
                    editing={editing}
                    handleItemDetail={handleItemDetail}
                    handleItemRemove={handleItemRemove}
                />
            case 'traceKm':
            case 'traceKms':
                return <RelationshipTraceKm
                    k={k}
                    v={v}
                    editing={editing}
                    handleItemDetail={handleItemDetail}
                    handleItemRemove={handleItemRemove}
                />
            case 'tyreSet':
                return <RelationshipTyreSet
                    k={k}
                    v={v}
                    editing={editing}
                    handleItemDetail={handleItemDetail}
                    handleItemRemove={handleItemRemove}
                />
            default:
                return <RelationshipSimple
                    k={k}
                    v={v}
                    editing={editing}
                    handleItemDetail={handleItemDetail}
                    handleItemRemove={handleItemRemove}
                />
        }
    }


    let items;
    if (Array.isArray(value)) {
        items = value.map((v, k) => {
            if (k >= visiblelimit) return;
            return getItem(v, k);
        })
    } else if (typeof value === 'object' && value !== null) {
        items = getItem(value, 1);
    }

    return (
        <List variant="plain" className={"relationship-elements relationship-elements-" + view}>
            {/* {view} */}
            {/* {JSON.stringify(value)} */}
            {items}
            {(!editing && !items) && (
                <ListItem variant="plain">
                    <ListItemContent >{t('none')}</ListItemContent>
                </ListItem>
            )}
        </List>
    );
};

export default Relationship
