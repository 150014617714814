import React from "react"
import { Text } from "../Typography";
import { Box } from "@mui/joy";

const SectionTitleEmbedded = ({ level = 'title-sm', icon, className, label, tag }) => {

    return (
        <Box className={className ? "section-title-embedded "+className : "section-title-embedded"}>
            <Text level={level} icon={icon} children={label} />
            <Box className="tagwrapper">
                { tag }
            </Box>
        </Box>
    )
}

export default SectionTitleEmbedded