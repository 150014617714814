import React from "react";
import { Text } from '../Typography';
import { useTranslation } from 'react-i18next';

const ActiveModuleName = ({currentModuleScope, currentModuleName}) => {    
    const { t } = useTranslation();
    return (
        <div className="modulename">
            <Text level="h2">{(currentModuleScope) && (<span className="scope">{t(currentModuleScope)}</span>)}{t(currentModuleName)}</Text>
        </div>
    );
}

export default ActiveModuleName;