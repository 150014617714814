import { LostPassword, SignIn, SignOut, SignUp } from './views';

const Module = {
  name : "Auth",
  defaultRoutePath : "/auth",

  routes: [
    {
      path: "/",
      protected: false,
      children: [
        {
          index: true,
          Component: SignIn,
        },
        {
          path: "lost-password",
          Component: LostPassword,
        },
        {
          path: "sign-up",
          Component: SignUp,
        }
      ],
    },
    {
      path: "/",
      protected: true,
      children: [
        {
          path: "sign-out",
          Component: SignOut,
        },
      ],
    },
  ],
  dataProviders: [
    {
        "name": "auth",
        "endpoint": "auth/token",
    },
    
]
}

export default Module;
